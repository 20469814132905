<template>
    <div>
        <v-card :style="ajaBackground ? { backgroundColor: '#1E3945' } : {}" flat class="mb-12">
            <h3 class="mb-8"> Informations concernant votre enfant </h3>
            <v-form ref="ajaYoungForm" v-on:submit.prevent="onSubmit">
                <v-switch :color="ajaBackground ? '#4E983C' : 'primary'" v-model="agreeForYoungToFollow"
                    label="J’autorise mon enfant mineur à participer au programme expérimental oncogiteAJA qu’il accepte de suivre."
                    class="ml-4 font-weight-bold"></v-switch>
                <div v-if="agreeForYoungToFollow">
                    <v-text-field :color="ajaBackground ? '#4E983C' : 'primary'" v-model="young.lastname"
                        label="Nom de l'enfant" outlined :rules="[rules.required]"></v-text-field>
                    <v-text-field :color="ajaBackground ? '#4E983C' : 'primary'" v-model="young.firstname"
                        label="Prénom de l'enfant" outlined :rules="[rules.required]"></v-text-field>
                    <date-input :color="ajaBackground ? '#4E983C' : 'primary'" v-model="young.birthdate"
                        label="Date de naissance" @save="date => young.birthdate = date" required />
                    <v-text-field :color="ajaBackground ? '#4E983C' : 'primary'" v-model="young.email"
                        label="E-mail de l'enfant (un email l'invitant à finaliser son inscription lui sera envoyé)"
                        outlined :rules="[rules.required, rules.email]"></v-text-field>
                </div>
            </v-form>
        </v-card>
        <v-btn :color="ajaBackground ? '#4E983C' : 'primary'" @click="createYoungAccount">
            Valider
        </v-btn>

        <!-- Alert if user is the assistant and wan't to register a major young -->
        <v-dialog persistent v-model="majorYoungAlert" max-width="1000">
            <v-card :style="ajaBackground ? { backgroundColor: '#1E3945' } : {}" class="text-center pt-6">
                <v-card-title>
                    <h2>Bienvenu.e !</h2>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="majorYoungAlert = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="text-left">
                    Si votre enfant est majeur, il doit d'abord créer son compte. <br />
                    Vous pourrez ensuite créer le votre pour l'accompagner.<br />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            young: {
                lastname: '',
                firstname: '',
                birthdate: '',
                email: '',
            },
            agreeForYoungToFollow: false,
            rules: {
                required: value => !!value || 'Champ requis',
                email: value => {
                    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    return pattern.test(value) || 'E-mail invalide';
                },
            },
            majorYoungAlert: false,
        };
    },
    computed: {
        registrationProcess() {
            return typeof this.user.registration_process === 'string'
                ? JSON.parse(this.user.registration_process)
                : this.user.registration_process;
        },
        ajaBackground() {
            return this.registrationProcess.reason === 'aja'
        },
    },
    methods: {
        async createYoungAccount() {
            if (this.registrationProcess.reason === "aja" && this.registrationProcess.aja_role === "aja_assistant") {
                const age = this.$moment().diff(this.$moment(this.young.birthdate), 'years');
                if (age > 18) {
                    this.majorYoungAlert = true;
                    return;
                }
            }
            let assistant_id = this.$store.getters.getProfile.id
            if (this.$refs.ajaYoungForm.validate()) {
                this.loading = true
                this.$store.dispatch('createYoungAccount', { ...this.young, 'assistant_id': assistant_id })
                    .then(() => {
                        this.$emit('submitAjaAssistantForm', { ...this.user });
                    })
                    .catch((err) => {
                        this.$store.dispatch('setErrors', "Une erreur est survenue lors de la création du compte jeune");
                    })
            }
        },
    },
};
</script>
