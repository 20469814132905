<template>
    <div>
        <v-form class="mt-4" ref="userRegistrationForm" v-on:submit.prevent="submitUserRegistrationForm">
            <v-text-field :color="fieldColor" v-model="user.lastname" label="Nom" outlined
                          :rules="[rules.required]"></v-text-field>

            <v-text-field :color="fieldColor" v-model="user.firstname" label="Prénom" outlined
                          :rules="[rules.required]"></v-text-field>

            <date-input :color="fieldColor" v-model="user.birthdate" label="Date de naissance"
                        @save="date => setBirthDate(date)" required />

            <v-text-field :color="fieldColor" v-model="user.street" label="Adresse" outlined
                          :rules="[rules.required]" hide-details="auto" class="mb-2"></v-text-field>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field :color="fieldColor" v-model="user.zip" label="Code postal" outlined
                                  :rules="[rules.required]" @change="fetchCities" clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                    <v-autocomplete :color="fieldColor" v-model="user.city" :items="allCities" item-text="nom"
                                    item-value="nom" label="Ville" :loading="citiesLoading" :disabled="citiesLoading"
                                    outlined
                                    :rules="[rules.required]" hide-details="auto" no-data-text="Aucune ville trouvée"
                                    @change="fetchZip" return-object clearable></v-autocomplete>
                </v-col>
            </v-row>

            <v-text-field :color="fieldColor" v-model="user.phone" label="Téléphone" outlined
                          :rules="[rules.required]" @input="formatPhone"></v-text-field>

            <v-text-field :color="fieldColor" v-model="user.email" label="E-mail" outlined
                          :rules="[rules.required, rules.email]"></v-text-field>

            <v-text-field :color="fieldColor" v-model="user.password" label="Mot de passe" outlined
                          :rules="[rules.required, rules.minlength]"
                          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                          :type="showPassword ? 'text' : 'password'"
                          @click:append="togglePasswordVisibility"></v-text-field>

            <v-text-field :color="fieldColor" v-model="user.password_confirmation"
                          label="Confirmation du mot de passe" outlined :rules="[rules.required, rules.minlength]"
                          :append-icon="showConfirm ? 'visibility' : 'visibility_off'"
                          :type="showConfirm ? 'text' : 'password'"
                          @click:append="toggleConfirmVisibility"></v-text-field>

            <v-row justify="center">
                <v-col cols="12" class="text-center">
                    <div>
                        En adhérant à nos services, vous acceptez les
                        <a href="#" :style="{ color: fieldColor }" @click.prevent="cgDialog = true">
                            Conditions générales
                        </a>
                        de
                        <oncogite />
                    </div>
                    <div>
                        Veuillez également consulter notre notice
                        <a href="#" :style="{ color: fieldColor }"
                           @click.prevent="confidentialityDialiog = true">
                            Protection de vos Informations Personnelles
                        </a>
                    </div>
                </v-col>

                <v-col cols="auto">
                    <vue-recaptcha class="mb-8" :sitekey="recaptchaKey" :loadRecaptchaScript="true"
                                   @verify="canSubmit = true" @error="canSubmit = false"
                                   @expired="canSubmit = false"></vue-recaptcha>
                </v-col>
            </v-row>

            <v-btn :color="fieldColor" type="submit" :disabled="!canSubmit || loading" :loading="loading">
                Créer mon compte
            </v-btn>
            <v-btn text @click="goStepBack">
                Retour
            </v-btn>
        </v-form>

        <!-- Alert if user is young_aja but < 18yo -->
        <v-dialog persistent v-model="minorYoungAlert" max-width="1000">
            <v-card :style="{ backgroundColor: bgColor }" class="text-center pt-6">
                <v-card-title>
                    <h2>Bienvenu.e !</h2>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="minorYoungAlert = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text :style="{color: fieldColor}" class="text-left white--text">
                    Comme tu es encore mineur.e, un de tes parents ou représentant légal doit d'abord créer son compte
                    qui générera le tien automatiquement.<br />
                    La législation veut qu'ils donnent leur accord.<br /><br />
                    Reviens créer ton compte ensuite.<br />
                    Ils n’accéderont pas à ton espace personnel.<br />
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- CG DIALOG -->
        <v-dialog v-model="cgDialog" max-width="80%">
            <CGU closable @close="cgDialog = false" />
        </v-dialog>

        <!-- Confidentiality DIALOG -->
        <v-dialog v-model="confidentialityDialiog" max-width="80%">
            <Confidentiality closable @close="confidentialityDialiog = false" />
        </v-dialog>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import CGU from '@/components/CGU'
import Confidentiality from '@/components/Confidentiality'
import { mapGetters } from 'vuex'

export default {
    components: {
        VueRecaptcha,
        CGU,
        Confidentiality
    },
    props: {
        orientation: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            allCities: [],
            citiesLoading: false,
            showPassword: false,
            showConfirm: false,
            canSubmit: false,
            loading: false,
            recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
            cgDialog: false,
            confidentialityDialiog: false,
            minorYoungAlert: false,
            user: this.$store.getters.getProfile
            // for testing purpose
            // user: {
            //     lastname: 'TEST',
            //     firstname: 'TEST',
            //     birthdate: '2000-01-01',
            //     street: 'TEST',
            //     zip: '33000',
            //     city: 'Bordeaux',
            //     phone: '1234567890',
            //     email: (Math.random() + 1).toString(36).substring(7) + '@neomind.test',
            //     password: 'password',
            //     password_confirmation: 'password'
            // }
        }
    },
    computed: {
        isAjaYoung () {
            return this.orientation.reason === 'aja' && this.orientation.aja_role === 'aja_young'
        },
        bgColor () {
            return this.isThemeAja() ? '#294D5B' : 'primary'
        },
        fieldColor () {
            return this.isThemeAja() ? '#4E983C' : 'primary'
        },
        rules () {
            return {
                required: value => !!value || 'Champ requis',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value) || 'E-mail invalide',
                minlength: value => value?.length >= 8 || 'Le mot de passe doit contenir au moins 8 caractères'
            }
        }
    },
    watch: {
        user: {
            handler (newVal) {
                this.$emit('updateUser', newVal)
            },
            deep: true
        }
    },
    methods: {
        ...mapGetters(['isThemeAja']),
        togglePasswordVisibility () {
            this.showPassword = !this.showPassword
        },
        toggleConfirmVisibility () {
            this.showConfirm = !this.showConfirm
        },
        formatPhone () {
            this.user.phone = this.user.phone
                .replace(/\s/g, '')
                .match(/.{1,2}/g)
                .join(' ')
        },
        async fetchCities () {
            if (this.user.zip && this.user.zip.length === 5) {
                this.citiesLoading = true
                const url = `https://geo.api.gouv.fr/communes?codePostal=${this.user.zip}`
                try {
                    const { data } = await this.$axios.get(url)
                    this.allCities = data
                    if (this.allCities.length === 1) {
                        this.user.city = this.allCities[0].nom
                        this.user.zip = this.allCities[0].codesPostaux[0]
                    }
                } finally {
                    this.citiesLoading = false
                }
            }
        },
        fetchZip () {
            if (this.user.city && this.user.city.codesPostaux) {
                this.user.zip = this.user.city.codesPostaux[0]
                this.user.city = this.user.city.nom
            }
        },
        async submitUserRegistrationForm () {
            if (this.$refs.userRegistrationForm.validate()) {
                const createdUser = await this.createUser()
                if (createdUser) {
                    this.$emit('submitUserRegistrationForm', createdUser)
                }
            }
        },
        async setBirthDate (date) {
            this.user.birthdate = date
            if (this.isAjaYoung) {
                const age = this.$moment().diff(this.$moment(this.user.birthdate), 'years')
                if (age < 18) {
                    this.minorYoungAlert = true
                }
            }
        },
        async createUser () {
            if (this.$refs.userRegistrationForm.validate() && !this.loading) {
                const age = this.$moment().diff(this.$moment(this.user.birthdate), 'years')
                if (age < 18) {
                    this.minorYoungAlert = true
                    return
                }
                this.loading = true
                try {
                    this.user.registration_process = this.orientation
                    await this.$store.dispatch('registerRequest', this.user)
                    const { email, password } = this.user
                    await this.$store.dispatch('authRequest', { email, password })
                    return this.$store.getters.getProfile
                } catch (err) {
                    if (err.response.status !== 403) {
                        await this.$store.dispatch('setErrors', err.response.data)
                    }
                } finally {
                    this.loading = false
                }
            }
        },
        goStepBack () {
            this.$emit('goStepBack', 1)
        }
    }
}
</script>
