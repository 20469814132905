<template>
    <div>
        <legend class="text-caption">{{ label }}</legend>
        <v-row dense>
            <v-col v-if="['YYYY-MM-DD'].indexOf(format) > -1">
                <v-select 
                    ref="day"
                    v-model="day"
                    outlined
                    label="Jour"
                    :items="Array.from({length: 31}, (_, i) => String(i + 1).padStart(2, 0))"
                    :rules="required ? [rules.required] : []"
                    clearable
                ></v-select>
            </v-col>
            <v-col v-if="['YYYY-MM-DD', 'YYYY-MM'].indexOf(format) > -1">
                <v-select 
                    ref="month"
                    v-model="month"
                    outlined
                    label="Mois"
                    :items="Array.from({length: 12}, (_, i) => String(i + 1).padStart(2, 0))"
                    :rules="required ? [rules.required] : []"
                    clearable
                ></v-select>
                    <!-- :items="[{text: 'janvier', value: '01'},
                        {text: 'février', value: '02'},
                        {text: 'mars', value: '03'},
                        {text: 'avril', value: '04'},
                        {text: 'mai', value: '05'},
                        {text: 'juin', value: '06'},
                        {text: 'juillet', value: '07'},
                        {text: 'août', value: '08'},
                        {text: 'septembre', value: '09'},
                        {text: 'octobre', value: '10'},
                        {text: 'novembre', value: '11'},
                        {text: 'décembre', value: '12'}]" -->
            </v-col>
            <v-col>
                <v-select 
                    ref="year"
                    v-model="year"
                    outlined
                    label="Année"
                    :items="Array.from({length: 120}, (_, i) => i + ($moment().year() - 119)).reverse()"
                    :rules="required ? [rules.required] : []"
                    clearable
                ></v-select>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        value: {type: String, default: ''},
        label: {type: String, default: 'Date'},
        required: {type: Boolean, default: false},
        format: {type: String, default: 'YYYY-MM-DD'},
    },
    data () {
        return {
            day: null,
            month: null,
            year: null,
            rules: {
                required: value => !!value || 'Champ requis',
            }
        }
    },
    watch: {
        day() {
            if (this.day) {
                this.$emit('save', this.formatted)
            }
        },
        month() {
            if (this.month && this.$refs.month.validate()) {
                this.$emit('save', this.formatted)
            }
        },
        year() {
            if (this.year && this.$refs.year.validate()) {
                this.$emit('save', this.formatted)
            }
        },
    },
    computed: {
        formatted() {
            let string = this.year
            if (['YYYY-MM-DD', 'YYYY-MM'].indexOf(this.format) > -1)
                string += '-'+this.month
            if (['YYYY-MM-DD'].indexOf(this.format) > -1)
                string += '-'+this.day
            return string
        }
    },
    mounted() {
        if (this.value) {
            let date = this.$moment(this.value)
            this.day = String(date.date()).padStart(2, 0)
            this.month = String(date.month()+1).padStart(2, 0)
            this.year = date.year()
        }
    },
    methods: {
        // 
    }

}
</script>