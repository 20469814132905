const state = () => ({
    snack: ''
})

const getters = {
    getSnack: state => () => state.snack
}

const actions = {
    setErrors: ({commit}, data) => {
        if (data.errors)
            commit('setSnack', {'type': 'red', 'msg': data.errors})
        else if (data.message)
            commit('setSnack', {'type': 'red', 'msg': data.message})
        else
            commit('setSnack', {'type': 'red', 'msg': data})
    }
}


const mutations = {
    setSnack (state, snack) {
        state.snack = snack
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}