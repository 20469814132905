<template>
    <div class="unauthorized">
        <v-main>
            <v-container fluid fill-height>
                <v-row justify="center">
                    <v-col cols="12" class="text-center">
                        <h1 class="headline my-5">Vous n'êtes pas autorisé à accéder à cette ressource</h1>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn color="secondary" @click="$router.go(-2)">Retourner en arrière</v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-btn depressed color="secondary" outlined @click="clearCache">Rafraichir mon profil</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 
        }
    },
    mounted() {
        if (this.$acl.check('isNotAuth'))
            this.$router.push({name: 'login'})
    },
    methods: {
        clearCache() {
            localStorage.clear()
            location.replace(process.env.VUE_APP_URL || '')
        }
    }
}
</script>