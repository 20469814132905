<template>
    <v-card>
        <!-- <CardTitle text="Conditions générales"/> -->
        <v-card-title class="justify-end" v-if="closable">
            <v-btn icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <div class="body-1 text-justify">
                <h1 class="my-5 pt-5 text-center">Mentions légales</h1>
                <p><b>Editeur</b>: Association onCOGITE, association loi 1901 enregistrée auprès de la Préfecture de la Gironde (33) sous le n°W332027865, dont le siège est sis 90 rue Saint Genès 33000 BORDEAUX</p>
                <p><b>Directeur de la publication</b>: Christophe MULLER</p>
                <p>Hébergeur : OVH</p>
                <p>Crédits photos : <oncogite/></p>
                
                <p>Utilisation des cookies</p>
                <p>Un cookie est un petit fichier texte déposé sur votre ordinateur. Certains cookies sont nécessaires au bon fonctionnement du site. Ils ont également pour but d’améliorer les services qui vous sont proposés en nous aidant à mieux comprendre la manière dont vous utilisez notre site. Vous pouvez à tout moment utiliser les paramètres de votre navigateur pour désactiver les cookies. Nous vous rappelons cependant que le paramétrage est susceptible d’affecter les conditions d’accès à nos services nécessitant l’utilisation de cookies.</p>

                <p>Données personnelles</p>
                <p>En créant votre compte, en vous inscrivant à des ateliers ou en remplissant nos formulaires en ligne, en faisant un don et/ou une adhésion via le site internet https://www.oncogite.com, vous acceptez de recevoir des informations de la part d’ onCOGITE.</p>
                <p>Les informations recueillies sont enregistrées dans un fichier informatisé [Eudonet] par la DPD d’ onCOGITE association pour envoyer des newsletters et campagnes d’informations propre à l’association. Elles sont conservées pendant une durée limitée et sont destinées à envoyer des contenus informatifs (suivi adhésion, newsletter, campagnes d’information de l’association onCOGITE). Conformément à la loi 78-17 du 6 janvier 1978 modifiée en 2004 relative à l’informatique, aux fichiers et aux libertés, et au Règlement 2016/ 679 du 27 avril 2016 dénommé Règlement Général sur la Protection des Données (RGPD) vous disposez d’un droit d’accès, de modification, de suppression et de rectification des données vous concernant. Pour pouvoir exercer votre droit d’accès, merci de nous écrire  sur https://www.oncogite.com</p>
                <p>Pour plus d’informations sur la protection de vos données personnelles, veuillez prendre connaissance de la Charte de Confidentialité relative à la protection des données à caractère personnel des utilisateurs.</p>

                <p>Dons et adhésions en ligne</p>
                <p>Notre plateforme de dons et adhésions est 100% sécurisée.</p>
                <p>Les transactions bancaires sont prises en charge par onCOGITE via E-Transaction du Crédit Agricole. Elles ne sont en aucun cas conservées sur nos systèmes informatiques et sont gérées par des plateformes spécialisées dans les cadres légaux régis par la loi.</p>

                <h1 class="my-5 pt-5 text-center">CGU (conditions générales d’utilisation)</h1>

                <h2 class="my-5 pt-5">1 - Présentation du service</h2>
                <p>Bienvenue sur le site <oncogite/></p>
                <p>Les outils de remédiation cognitive proposés par <oncogite/> permettent de prendre en charge différentes situations de patients, de s’adapter au besoin de chacun. Ils sont mis à disposition pour une durée d’un an correspondant à la durée de l’adhésion. Un renouvellement de cette adhésion sera proposé 15 jours avant son échéance.</p>
                <p>Les outils de paiement proposés par <oncogite/> permettent de prendre en charge plusieurs moyens de paiement (carte, chèque, prélèvement SEPA, sans que cette liste ne soit exhaustive) grâce à un dispositif simplifié et sans coût pour les Contributeurs.</p>
                <p>Afin d'utiliser les Services, il vous faut un accès à l'Internet soit directement sur votre ordinateur, soit par l'intermédiaire d'autres supports tels que le téléphone mobile, les assistants personnels. Les éventuels frais correspondants à ces accès sont à votre seule charge. De plus, vous devez vous munir de tout matériel nécessaire afin d'assurer cette connexion au réseau Internet.</p>
                <p>Il vous appartient de vous assurer que votre matériel et vos logiciels ne perturberont pas ni n'interféreront avec les Services <oncogite/>. Vous devez déconnecter immédiatement tout appareil ou logiciel qui causerait des interférences avec les Services. Il est souhaitable que vous conserviez les Contenus échangés ou stockés sur les Services sur une copie de sauvegarde afin de vous prémunir de tous risques de perte.</p>

                <h2 class="my-5 pt-5">2 - Définitions</h2>
                <p>Les termes ci-après définis et dont la première lettre figure en lettres majuscules dans le présent Accord ont la signification qui leur est attribuée au présent article.</p>
                <p><b>Accord :</b> désigne le présent document, ses Annexes ainsi que ses éventuels avenants ultérieurs.</p>
                <p><b>Adhérent bénéficiaire :</b> désigne les personnes physiques - patients soignés pour un cancer- qui payent la cotisation annuelle de base pour bénéficier des services de remédiation cognitive proposés par <oncogite/>.</p>
                <p><b>Adhérent professionnel :</b> désigne un professionnel de santé (neuropsychologue en particulier) qui a cotisé à <oncogite/> comme membre actif afin bénéficier des services destinés aux professionnels mettant en œuvre le parcours de remédiation <oncogite/> .</p>
                <p><b>Annexe :</b> désigne toute annexe au présent Accord.</p>
                <p><b>Compte de paiement :</b> compte ouvert dans les livres comptables d’<oncogite/></p>
                <p><b>Contenus :</b> désigne les textes, photos, vidéos et toute autre forme de contenu mis en ligne par <oncogite/> ou par ses prestataires pour son compte.</p>
                <p><b>Contributeur :</b> désigne la personne qui s’est inscrite sur le site <oncogite/> et qui réalise un paiement, quel que soit le mode utilisé (chèque ; carte ; prélèvement SEPA).</p>
                <p><b>Logo :</b> désigne le logo destiné à l’identification de <oncogite/></p>
                <p><b>Marque :</b> désigne la marque française ou internationale déposée et destinée à l’identification de l’activité de l’association.</p>
                <p><b>Membres actifs :</b> désigne les membres actifs d’<oncogite/>. qui prennent part au développement de l’association, à sa vie démocratique et payent une cotisation majorée.</p>
                <p><b>Partenaires :</b> désigne, pour <oncogite/>, les personnes ou entités tierces avec lesquelles des relations sont entretenues dans le cadre de ses activités.</p>
                <p><b>Reçu Fiscal :</b> désigne une attestation de versement ouvrant droit à un avantage fiscal au titre des articles 200, 238 bis et 885-0 V bis du Code Général des Impôts.</p>
                <p><b>Propriété intellectuelle <oncogite/> :</b> désigne tout élément susceptible de protection par le droit de la propriété intellectuelle ou industrielle, tel que notamment, le logo, la marque et les contenus originaux d’<oncogite/>.</p>
                <p><b>Services :</b> désigne les services en ligne d’<oncogite/> disponible à l’adresse http://www.oncogite.com, c’est-à-dire la possibilité donnée à un adhérent d’utiliser ces services.</p>
                <p><b>Utilisateur :</b> désigne un internaute navigant sur le site, sans s’être inscrit sur le site et dont l’identité est inconnue.</p>

                <h2 class="my-5 pt-5">3 - Acceptation</h2>
                <p>Toute utilisation ou visualisation des Services fournis par <oncogite/>. ou sur le site oncogite.com, implique l’adhésion sans réserve aux présentes conditions d'utilisation (ci-après « Conditions d'Utilisation ») </p>
                <p>En outre, les opérations de paiement réalisées sur notre site, impliquent, conformément aux dispositions du Code monétaire et financier, l’utilisation d’un système de paiement géré par un établissement agréé.</p>
                <p>Par conséquent, l’utilisation d’un moyen de paiement suppose l’acceptation des conditions du système de paiement proposé sur le site <oncogite/>. A cette fin, <oncogite/> a établi différents partenariats avec des établissements agréés qui permettent aux Contributeurs d’effectuer leurs paiements en toute sécurité.</p>
                <p>Le Contributeur accepte de communiquer les données permettant son identification dans le cadre du système de paiement proposé sur le site.</p>

                <h2 class="my-5 pt-5">4 - Fonctionnement</h2>
                <p><oncogite/> met à disposition de ses Utilisateurs un service en ligne leur permettant de réaliser tout type de paiement au profit de l’association.</p>
                <p>L’adhésion à  <oncogite/>.est le préalable indispensable à toute participation en tant que patient ou professionnel au parcours de remédiation cognitive <oncogite/>.</p>

                <h2 class="my-5 pt-5">5 - Utilisation</h2>

                <h3 class="my-3 pt-3">5.1 - Inscription</h3>
                <p>Dès qu’un Utilisateur créée un compte <oncogite/>, il lui est proposé de devenir Contributeur.</p>
                <p>La création d'un compte <oncogite/> implique la fourniture d'informations permettant l'identification du Contributeur, renseignées dans un formulaire d'inscription au Service. Ces informations sont ensuite stockées et accessibles dans la rubrique « Mon Compte », qui apparaît, dès que vous avez ouvert une session avec votre adresse mail et votre mot de passe.</p>
                <p>Vous avez une obligation légale d'identification dès que vous utilisez des services <oncogite/>.. Dans cette hypothèse, en qualité de personne physique agissant à titre professionnel ou non-professionnel, vous vous engagez à :
                    <br>• fournir des informations vraies, exactes, et complètes, et vérifiables
                    <br>• les maintenir et les remettre à jour régulièrement.
                </p>
                <p>La fourniture de ces informations et leur maintien à jour de façon à permettre votre identification sont des conditions déterminantes de votre droit d'utiliser le Service.</p>

                <h3 class="my-3 pt-3">5.2 - Compte utilisateur, mot de passe et sécurité</h3>
                <p>Pour créer un Compte <oncogite/>, vous entrez votre adresse mail et choisissez un mot de passe.</p>
                <p>Ce Compte <oncogite/> vous est strictement personnel et vous vous interdisez de le partager ou le céder à qui que ce soit. Cela constitue une obligation essentielle de ce contrat.</p>
                <p>Vous êtes responsable (i) de la conservation du caractère confidentiel de votre mot de passe et (ii) des actions qui sont effectuées sous votre Nom de Compte et/ou avec votre mot de passe : si vous ouvrez une session sur un ordinateur public, veillez à fermer votre session lorsque vous quittez cet ordinateur. Ne communiquez votre mot de passe à personne.</p>
                <p>Votre Nom et Prénom pourront être reproduits sur des sites d’<oncogite/> au titre des Services disponibles que vous utilisez sur lesdits sites, ainsi que dans les cas de distribution des services sur des sites partenaires.</p>
                <p>En cas de perte ou de vol de votre mot de passe et/ou de votre adresse mail, ou si vous prenez connaissance de leur utilisation par un tiers non autorisé, vous devez immédiatement nous en informer.</p>
                <p><oncogite/> ne pourra être tenue responsable de l’utilisation frauduleuse  de votre mot de passe et/ou de votre adresse mail par un tiers non autorisé.</p>
                <p><oncogite/> se réserve le droit de vous demander de modifier votre mot de passe et/ou l’adresse mail vous servant d’identifiant dans le cas où elle se rendrait compte d’une quelconque utilisation frauduleuse de ceux-ci.</p>

                <h3 class="my-3 pt-3">5.3 - Reçu fiscal</h3>
                <p>Lorsque les Contributeurs du site <oncogite/> font un paiement, ils doivent indiquer leur Nom, Prénom, adresse et Adresse Mail ainsi que définir le montant du versement fait.</p>
                <p>Une fois que le Contributeur a finalisé son paiement sur le Service, <oncogite/> lui envoie automatiquement par courrier électronique une attestation de paiement, que celui-ci peut à tout moment retrouver dans son espace personnel sur le site www.oncogite.com.</p>
                <p><oncogite/> a été déclarée organisme d’intérêt général, de ce fait, elle est habilitée à émettre des Reçus fiscaux au titre des articles 200, 238 bis et 978 du code général des impôts. <oncogite/> envoie donc à l’Utilisateur un Reçu Fiscal, en plus de l’attestation de paiement.</p>

                <h3 class="my-3 pt-3">5.4 - Résiliation</h3>
                <p>Tout utilisateur pourra résilier son Compte <oncogite/> à tout moment en ouvrant une session avec son e-mail et mot de passe et en cliquant sur « Se désinscrire de <oncogite/> ».</p>
                <p>Cette résiliation entraînera automatiquement l'inaccessibilité de l'ensemble des Contenus stockés sur le Service et en particulier les justificatifs de paiement. Il est donc conseillé aux utilisateurs de veiller préalablement à la sauvegarde de leurs contenus sur un autre compte ou sur tout support de leur choix.</p>

                <h3 class="my-3 pt-3">5.5 - Indivisibilité des présentes conditions générales d’utilisation et du système de paiement proposé par le Crédit Agricole</h3>
                <p>Le système de paiement proposé sur le site est celui géré par la banque coopérative Crédit Agricole. L’utilisation du Service implique pour le Contributeur d’accepter de communiquer à cette société les informations nécessaires à son identification et au paiement qu’il entend réaliser. Ces données sont gérées de manière confidentielle par le Crédit Agricole, et non par <oncogite/>.</p>

                <h3 class="my-3 pt-3">5.6 - Délai de rétractation, annulation et remboursement</h3>
                <p>Les paiements en ligne ne font pas l'objet d'un droit de rétractation. Tout paiement est ferme et définitif.</p>
                <p>Les paiements réalisés par les Contributeurs sur le site <oncogite/> ne donnent lieu à aucun remboursement.</p>

                <h2 class="my-5 pt-5">6 - Responsabilité d’<oncogite/></h2>
                <p><oncogite/> décline expressément toute responsabilité concernant le site <oncogite/>, contenu et services. Le site <oncogite/> son contenu et ses services sont fournis « en l'état » et l'utilisation que vous en faites est à vos propres risques. <oncogite/> ne garantit pas que l'accès au site oncogite.com et à ses services se fera de manière continue et sans erreurs, que le site oncogite.com sera sans virus, que l'information présente sur le site oncogite.com sera précise, complète et fiable.</p>
                <p>L'utilisation des services du site se fait à vos propres risques. <oncogite/> ne saurait être tenu responsable des dommages éventuels causés à votre système informatique ou de la perte de données résultant de l'utilisation des services du site.</p>
                <p>Aucune information, conseil ni aucun renseignement écrit ou oral fourni par <oncogite/> ou un tiers ne saurait créer des garanties non énoncées expressément dans le présent contrat.</p>
                <p><oncogite/> ne saurait en aucun cas être tenu pour responsable de tout problème rencontré suite à l’utilisation du service et de toutes ses conséquences éventuelles.</p>

                <h3 class="my-3 pt-3">6.1 - Contenu</h3>
                <p>Les Visiteurs et Utilisateurs du site oncogite.com sont seuls responsables des décisions qu’ils prennent suite à la consultation des informations disponibles sur le site oncogite.com. Ils sont invités à consulter également d’autres sources d’information et à effectuer leurs propres recherches.</p>
                <p><oncogite/> ne peut voir sa responsabilité engagée sur les contenus diffusés et disponibles à partir des liens hypertextes vers d'autres sites Internet présents sur son site ou ailleurs.</p>

                <h3 class="my-3 pt-3">6.2 - Liens hypertextes</h3>
                <p>Des liens sont disponibles sur le site oncogite.com qui dirigent vers d'autres sites Web ou d'autres sources Internet.</p>
                <p><oncogite/> n'a pas le contrôle ni la responsabilité des sites Web de tiers ou documents externes vers lesquels redirigent ces liens. Ces sites Web, documents et contenus de toute nature sont susceptibles d'être modifiés, mis à jour ou supprimés à tout moment par l'éditeur qui prend la responsabilité de leur accessibilité au public. <oncogite/> n'exerce pas de contrôle a priori sur la formulation de ces liens ni sur le contenu des sites vers lesquels ils renvoient et ne peut donc être tenu responsable de ce fait.</p>

                <h3 class="my-3 pt-3">6.3 - Indisponibilité</h3>
                <p>oncogite.com met tout en œuvre pour être disponible à chaque instant et partout en France. Toutefois compte tenu des besoins de maintenance ou pour d’autres raisons, il peut arriver que les services de <oncogite/> soient momentanément indisponibles.</p>
                <p><oncogite/> ne saurait en aucun cas être tenu responsable des inconvénients éventuels résultant de l’indisponibilité du site oncogite.com</p>

                <h2 class="my-5 pt-5">7 - Données à caractère personnel</h2>
                <p><oncogite/> pratique une politique de protection des données personnelles dont les caractéristiques sont explicitées dans le document intitulé Charte de CONFIDENTIALITE, relative à la protection des données à caractère personnel, accessible sur la page d’accueil du Service et dont vous êtes expressément invités à prendre connaissance.</p>
                <p><oncogite/> déclare notamment respecter toutes les obligations légales et réglementaires qui lui incombent en matière de protection des données à caractère personnel visant à garantir notamment la sécurité et la confidentialité des données collectées et traitées. Elle s’engage à ce titre à respecter la règlementation en vigueur applicable au traitement de données à caractère personnel et notamment au Règlement européen sur la protection des données n°2016/679 du 27 avril 2016.</p>
                <p>Dans le cadre de leurs relations contractuelles, les Parties s’engagent à respecter la règlementation en vigueur applicable au traitement de données à caractère personnel et notamment au Règlement européen sur la protection des données n°2016/679 du 27 avril 2016.</p>

                <h2 class="my-5 pt-5">8 - Responsabilité des utilisateurs</h2>

                <h3 class="my-3 pt-3">8.1 - Les règles de conduite à respecter</h3>
                <p>Vous êtes responsable de tout Contenu (i) que vous rendez accessible à des tiers, (ii) que vous envoyez par courrier électronique ou (iii) que vous stockez sur des espaces personnels du Service ou (iv) sur des espaces accessibles à des tiers, (v) que vous téléchargez, ou (vi) transmettez de toute autre manière par le Service.</p>
                <p>Vous êtes par ailleurs conscient que tout Contenu accessible au public mis en ligne par un des utilisateurs du Service relève de la responsabilité de celui-ci, <oncogite/> n'exerçant pas de contrôle a priori sur lesdits Contenus. <oncogite/> n'a pas d'obligation générale de surveillance du contenu stocké sur ses Services par les utilisateurs.</p>
                <p>Vous vous interdisez dans le cadre de l'utilisation des Services de vous livrer à des actes, de quelque nature que ce soit (notamment à des actes d’envoi, diffusion, édition, émission, mise en ligne, publication ou de toute autre manière), qui seraient contraires à la loi française, porteraient atteinte à l'ordre public français, ou aux droits d'un tiers. En particulier, et sans que cette liste soit limitative, vous vous interdisez de :</p>
                <p>Vous livrer à des actes constitutifs d'apologie des crimes contre l'humanité, de négation de génocides, d'incitation à la violence, à la haine raciale ou à la pornographie infantile ;</p>
                <p>• Vous livrer à des actes de diffamation, d'injure, de menaces, de chantage, de harcèlement ou à des actes attentatoires à la vie privée ou à la dignité humaine ;</p>
                <p>• Porter atteinte d'une quelconque manière aux utilisateurs mineurs, de les inciter à se mettre en danger d'une quelconque manière ;</p>
                <p>• En particulier, transmettre, diffuser, éditer, publier ou rendre accessible tout Contenu qui pourrait être constitutif, sans que cette liste soit limitative, d'incitation à la réalisation de crimes et délits ; de propagation de fausses nouvelles ou d'informations financières couvertes par le secret, de même que tout Contenu destiné à représenter ou proposer à la vente des objets et/ou des ouvrages, des logiciels, des Contenus interdits par la loi ou portant atteinte aux droits de tiers ; d'atteinte à l'autorité de la justice ; d'atteinte à la vie privée, à la protection des données personnelles ou au secret des correspondances ; de divulgation d'informations couvertes par un secret relatives, notamment à l'adoption plénière, à un procès en cours, au suicide, ou à la santé d'un tiers, ou à une situation patrimoniale ou financière individuelle couverte par le secret ou par le droit à l'intimité de la vie privée; ou encore d'acte mettant en péril des mineurs notamment par la fabrication, la transmission, la diffusion ou l'accessibilité de messages à caractère violent ou pornographique, de nature à porter atteinte à la dignité humaine ou de nature à permettre la fabrication d'explosifs ;</p>
                <p>• Tenter d'induire en erreur d'autres utilisateurs en usurpant l'identité ou une dénomination sociale ou en portant atteinte à l'image ou à la réputation d'autres personnes et/ou en vous faisant passer pour un tiers ou pour un employé, un service habilité ou un affilié de <oncogite/> ;</p>
                <p>• Falsifier des données, messages ou documents, des en-têtes de messages ou de données d'identification ou de connexion à un Service ou manipuler de toute autre manière un identifiant de manière à dissimuler l'origine de la transmission d'un Contenu via le Service ;</p>
                <p>• Vous livrer à une violation des droits de propriété intellectuelle (notamment en matière de vidéo, animations, jeux, logiciels, bases de données, images, sons et textes) ou tout autre droit de propriété (ci-après dénommés collectivement les « Droits ») appartenant à autrui ;</p>
                <p>• Expédier ou faire expédier des courriers électroniques ou des messages instantanés à des personnes qui ne les ont pas sollicités ou sans avoir respecté leurs droits reconnus par la loi, tels que des publicités, du matériel promotionnel, des chaînes de lettres ou toute autre forme de prospection directe non sollicitée ; mettre en ligne des messages à caractère promotionnel sur les Services <oncogite/> ;</p>
                <p>• Télécharger sciemment, afficher, émettre, diffuser, transmettre ou rendre accessible de toute autre manière tout Contenu comprenant ou constituant des virus informatiques ou tout autre code ou programme informatique conçus pour interrompre, détruire, détourner ou limiter les fonctionnalités ou les performances de tout logiciel, ordinateur, service ou outil de communications électroniques sans que cette énumération ne soit limitative ;</p>
                <p>• Perturber, ralentir, bloquer ou altérer le flux normal des données échangées dans le cadre du Service, accélérer le rythme de défilement des Contenus du Service de telle manière que le fonctionnement du Service soit modifié ou altéré ou commettre toute autre action ayant un effet perturbateur équivalent sur les fonctionnalités du Service ;</p>
                <p>• Accéder frauduleusement, se maintenir, entraver ou perturber les systèmes d'information de <oncogite/> et notamment des Services, les serveurs, les réseaux connectés au Service, ou refuser de se conformer aux conditions requises, aux procédures, aux règles générales ou aux dispositions réglementaires applicables aux réseaux connectés au Service.</p>

                <h3 class="my-3 pt-3">8.2 - Le signalement des abus</h3>
                <p>Si vous constatez ou estimez que du Contenu stocké sur le Service présente un caractère manifestement illicite, vous pouvez le signaler à <oncogite/> en envoyant un mail à contact@oncogite.com</p>
                <p>Votre notification doit impérativement permettre à <oncogite/> d'identifier le Contenu illicite et le chemin d'accès à ce contenu. Cette notification doit contenir les éléments suivants :
                    <br>• Vos noms, prénoms et domicile ;
                    <br>• Une copie du contenu qui vous semble litigieux ainsi que l'adresse URL de la page concernée ou les nom et prénom de l’auteur du contenu litigieux ;
                    <br>• Les motifs pour lesquels vous pensez que le contenu doit être retiré.
                </p>
                <p>Toute notification d'un contenu abusivement présenté comme illicite dans le but d'en obtenir le retrait vous exposerait à des sanctions civiles et/ou pénales.</p>

                <h3 class="my-3 pt-3">8.3 - Inscription des Utilisateurs</h3>
                <p>Une seule inscription utilisateur par personne physique et morale est autorisée. En revanche, les Utilisateurs peuvent regrouper plusieurs adresses mails sur le même compte. En cas de fraude, <oncogite/> se réserve le droit de supprimer tous les comptes de ces utilisateurs.</p>

                <h3 class="my-3 pt-3">8.4 - Suspension du compte créé par l’Utilisateur</h3>
                <p><oncogite/> pourra procéder à la suspension immédiate du compte créé sur le site <oncogite/>, de plein droit et sans préavis :</p>
                <p>• En cas de non-respect des présentes conditions générales ;</p>
                <p>• En cas de non-respect des conditions générales d’utilisation proposées par E-Transaction ;</p>
                <p>• En cas de blocage du compte de paiement par E-Transaction;</p>
                <p>• En cas de non-respect de la charte des valeurs morales  d’ <oncogite/> ;</p>
                <p>• En cas de données d'identification inexactes, périmées ou incomplètes ;</p>
                <p>• En cas de risque de fraude, de blanchiment de capitaux ou financement du terrorisme ou de risque pouvant affecter la sécurité du Compte de paiement ou le fonctionnement du site <oncogite/> ;</p>
                <p>• En cas de réception par E-Transaction d’un nombre important de demandes de remboursement, d’annulation d’ordres ou de contestation pour ordres non autorisés ;</p>
                <p>• Lorsque des Contenus ont fait l’objet de signalisations par les Internautes ;</p>
                <p>Le cas échéant, <oncogite/> vous contactera dans les meilleurs délais possibles pour tenter de remédier à la difficulté soulevée.</p>

                <h3 class="my-3 pt-3">8.5 - Suppression du compte créé par l’Utilisateur</h3>
                <p><oncogite/> se réserve le droit de supprimer le compte créé par l’Utilisateur qui viendrait à :</p>
                <p>• Pirater le site de quelque manière que ce soit ;</p>
                <p>• Contrevenir aux obligations des présentes conditions générales ou aux obligations contenues dans les conditions générales d’utilisation proposées par <oncogite/>;</p>
                <p>• Utiliser des robots de fausses inscriptions, ou de faux envois de mail ;</p>
                <p>• S’inscrire sous le nom d’une autre personne ;</p>
                <p>• Fournir des informations personnelles fausses ou invérifiables ;</p>
                <p>• S’inscrire plusieurs fois.</p>

                <h3 class="my-3 pt-3">8.6 - Comportement illicite</h3>
                <p>L’utilisateur s'interdit d'obtenir les adresses e-mail des autres utilisateurs afin de nuire au fonctionnement normal de leur courrier électronique. Tous les mails de type " Mails Bombing", " Flamming ", " Spamming " sont interdits.</p>

                <h3 class="my-3 pt-3">8.7 - Indemnisation</h3>
                <p>Au titre de votre utilisation du site oncogite.com et du Logiciel de <oncogite/>, vous acceptez d'indemniser <oncogite/>, ses fournisseurs, ses employés, ses dirigeants et autres tierces partie de tous les dommages directs, indirects, consécutifs, spéciaux, fortuits, accessoires, exemplaires, de toute perte, toute demande de dommages-intérêts, incluant les honoraires d'avocat et autres frais judiciaires, occasionnés par vous-même ou tout tiers résultant de ou étant associé à votre utilisation du Logiciel et/ou résultant de toute violation des « Conditions Générales d’utilisation »</p>

                <h2 class="my-5 pt-5">9 - Copyright et propriété intellectuelle</h2>

                <h3 class="my-3 pt-3">9.1 - Droits de propriété intellectuelle d’<oncogite/></h3>
                <p>Toutes les informations, écrans, graphismes, données, textes, logiciels, musiques, sons, photographies, images, vidéos, messages, avis, opinions, ou commentaires de toute sorte ou tous autres matériels présents sur oncogite.com sont la propriété exclusive de <oncogite/>. De ce fait toute reproduction, modification, distribution de ces éléments est interdite sans l'accord préalable exprès de son propriétaire.</p>
                <p>Les Services ainsi que tout logiciel, contenu ou matériel utilisé par <oncogite/> en relation avec les Services (ci-après dénommé « Logiciels ») contiennent des informations confidentielles qui sont protégées notamment par le code de la propriété intellectuelle. Sans préjudice des conditions particulières ou licence d'utilisation auxquelles vous souscrivez lors de l'utilisation d'un service spécifique, <oncogite/> vous concède une licence personnelle, non cessible, et non exclusive d'utiliser le Logiciel sous sa forme exécutable sur un seul ordinateur à la fois (ou téléphone portable ou autre appareil assimilé permettant l'accès au Service), étant entendu qu'il vous est interdit de copier, modifier, intégrer les Logiciels dans une œuvre dérivée, ou d'user de toute autre méthode pour tenter d'accéder au code source ou aux protocoles des Logiciels. <oncogite/> se réserve la faculté de corriger tout dysfonctionnement du Logiciel. Vous n'avez pas le droit de vendre, céder, sous-licencier, donner en garantie ou transmettre de toute autre manière les Logiciels. Vous vous engagez en outre à ne pas acheter, expédier, transporter, exporter, ou réexporter les Logiciels en dehors de l'Union européenne, sauf dans les cas autorisés par la loi.</p>
                <p>Vous reconnaissez également que le Contenu compris dans des publicités, les informations qui vous sont présentées via le Service ou par des annonceurs tiers sont protégés par le droit d'auteur, le droit des marques, le droit des brevets, ou tout autre droit reconnu par la législation en vigueur.</p>
                <p>Pour ce qui concerne le Contenu que vous stockez ou mettez en ligne sur les Services en vue de le rendre accessible à des tiers, vous accordez à <oncogite/>, un droit non-exclusif et gratuit d'utilisation permettant à <oncogite/> de reproduire, publier et diffuser ce Contenu aux fins de fourniture du Service, de sa promotion et de sa distribution, et ce, sur tout support électromagnétique et par tout moyen de communication électronique, sur les sites de <oncogite/>. Ce droit est accordé pour la durée pendant laquelle vous déciderez d'inclure le Contenu sur le Service. Vous garantissez à <oncogite/> d’avoir préalablement obtenu les droits nécessaires à cette exploitation et que le contenu est conforme à la loi et ne porte pas atteinte aux droits de tiers.</p>

                <h3 class="my-3 pt-3">9.2 - Interdiction d'exploitation des services fournis</h3>
                <p>Le Service est la propriété de <oncogite/> et/ou de ses ayants droits. L'utilisation personnelle des Services offerts sur le Site <oncogite/> exclut l'exploitation à toute autre fin. Vous vous interdisez notamment toute exploitation commerciale ou à but lucratif de tout ou partie des Services ou de l'accès aux Services. En conséquence, vous vous interdisez de reproduire, copier, vendre, revendre, échanger ou exploiter dans un but commercial ou moyennant une quelconque rémunération, profit ou avantage, tout ou partie du Service, toute utilisation du Service, ou tout droit d'accès au Service.</p>

                <h2 class="my-5 pt-5">10 - Autres dispositions</h2>

                <h3 class="my-3 pt-3">10.1 - Illégallité d'une clause</h3>
                <p>Le fait qu’une disposition de ces « Conditions générales d’utilisation du Service <oncogite/> » se révèle être, en totalité ou en partie, illicite ou inapplicable pour quelque raison que ce soit, sera sans incidence sur la validité des autres clauses des présentes conditions d’utilisation.</p>

                <h3 class="my-3 pt-3">10.2 - Cession des droits</h3>
                <p>En cas d’acquisition de la société <oncogite/> par une tierce partie ou de fusion entre <oncogite/> et une tierce partie, <oncogite/> se réserve le droit de céder tout ou partie de ses droits et obligations au titre des présentes conditions d’utilisation à la dite tierce partie ou à la nouvelle entité résultant de la fusion.</p>

                <h3 class="my-3 pt-3">10.3 - Informations complémentaires</h3>
                <p>Toutes les questions concernant les services proposés par <oncogite/> sont les bienvenues et peuvent être posées par mail à contact@oncogite.com</p>

                <h3 class="my-3 pt-3">10.4 - Modification</h3>
                <p><oncogite/> se réserve la possibilité de modifier les présentes dispositions à tout moment et sans préavis. Les nouvelles conditions d’utilisation du site <oncogite/> seront disponibles sur le site oncogite.com et la continuation de l’utilisation du service <oncogite/> vaudra acceptation de ces conditions. Ainsi, nous vous conseillons de les consulter régulièrement.</p>

                <h3 class="my-3 pt-3">10.5 - Droit applicable et litiges</h3>
                <p>Les présentes CGU sont régies, interprétées et appliquées conformément au droit français.</p>
                <p>Si l’utilisation du site <oncogite/> contrevient à la législation du pays dans lequel réside l'utilisateur ou le Visiteur, il convient pour celui-ci de ne pas utiliser le site oncogite.com. Il est le mieux placé pour juger de l’opportunité et de la légalité de cette utilisation. L'utilisation du site oncogite.com se fait aux propres risques de chaque utilisateur.</p>
                <p><oncogite/> fera tout son possible pour trouver les moyens satisfaisants de résoudre les problèmes rencontrés sur son site. Si cela ne s’avérait pas possible, <oncogite/> pourrait solliciter une médiation, un arbitrage, ou l’introduction d’une instance auprès d’un tribunal.</p>

                <h3 class="my-3 pt-3">10.6 - Assistance</h3>
                <p>En cas de réclamation, de plainte émanant de tiers ou d'action judiciaire formée à l'encontre de <oncogite/> ses représentants, ses salariés, ou ses partenaires, consécutive à l'envoi, l'affichage, la diffusion, l'émission, l'édition, la mise en ligne ou la transmission de Contenu par vos soins sur les Services, ou du fait de votre utilisation des Services, vous vous engagez à assister <oncogite/>, ses représentants, salariés, ou partenaires dans la préparation de leur réponse ou défense en leur communiquant tous documents, données ou informations utiles à cette fin.</p>
            </div>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="closable">
            <v-btn text color="primary" @click="$emit('close')">Fermer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default
{
    props: {
        closable: {
            type: Boolean,
            default: false,
        },
    },
}
</script>