import Vue from 'vue'

const state = {
    access_token: localStorage.getItem('access_token') || '',
    expires_in: localStorage.getItem('expires_in') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
    expires_at: localStorage.getItem('expires_at') || '',
}

const getters = {
    isAuthenticated: state => !!state.access_token,
}

const actions = {
    authRequest: ({commit, dispatch}, payload) => {
        let actionUrl = '/oauth/token';
        let data = {
            'username': payload.email,
            'password': payload.password,
            'grant_type': 'password',
            'client_id': process.env.VUE_APP_API_CLIENT_ID,
            'client_secret': process.env.VUE_APP_API_CLIENT_SECRET
        }   
        if (payload.action == 'password-reset') {
            actionUrl = '/password/reset';
            data = {
                'token': payload.token,
                'email': payload.email,
                'password': payload.password,
                'password_confirmation': payload.password_confirmation
            }
        }
        return new Promise((resolve, reject) => {
            Vue.axios.post(actionUrl, data)
                .then(async (resp) => {
                    commit('authSuccess', resp.data)
                    await dispatch('profileRequest')
                    // commit('setSnack', {'type': 'success', 'msg': 'Bienvenue !'})
                    resolve(resp.data)
                })
                .catch((err) => {
                    localStorage.removeItem('access_token')
                    if (err.response.data.error)
                        commit('setSnack', {'type': 'error', 'msg': 'Identifiants incorrects'})
                    else
                        commit('setSnack', {'type': 'error', 'msg': err.response.data.message})
                    reject(err)
                })
        })
    },
    authRefreshRequest: ({commit}) => {
        let actionUrl = '/oauth/token';
        let data = {
            'grant_type': 'refresh_token',
            'refresh_token': localStorage.getItem('refresh_token'),
            'client_id': process.env.VUE_APP_API_CLIENT_ID,
            'client_secret': process.env.VUE_APP_API_CLIENT_SECRET
        }   
        return new Promise((resolve, reject) => {
            Vue.axios.post(actionUrl, data)
                .then(async (resp) => {
                    commit('authSuccess', resp.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    localStorage.removeItem('access_token')
                    if (err.response.data.error)
                        commit('setSnack', {'type': 'error', 'msg': 'Une erreur est surevenue'})
                    else
                        commit('setSnack', {'type': 'error', 'msg': err.response.data.message})
                    reject(err)
                })
        })
    },
    authLogout: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post('api/logout')
                .then(() => {
                    commit('authLogout') 
                    commit('setSnack', {'type': 'warning', 'msg': 'Vous avez été déconnecté'})
                    commit('profileRemove')
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },
}

const mutations = {
    authSuccess: (state, authResponse) => {
        localStorage.setItem('access_token', authResponse.access_token)
        localStorage.setItem('expires_in', authResponse.expires_in)
        localStorage.setItem('refresh_token', authResponse.refresh_token)
        localStorage.setItem('expires_at', authResponse.expires_in + Date.now())
        state.access_token = authResponse.access_token
        state.expires_in = authResponse.expires_in
        state.refresh_token = authResponse.refresh_token
        state.expires_at = authResponse.expires_in + Date.now()
    },
    authLogout: (state) => {
        localStorage.clear();
        state.access_token = ''
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}