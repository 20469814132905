<template>
    <svg
        class="online-workshop-icon" 
        :style="{'width': width, 'height': height}"
        x="0px"
        y="0px"
        viewBox="0 0 149.79659 99.999997"
    >
        <switch transform="matrix(2.283105,0,0,2.283105,-6397.2602,-3185.6164)">
            <g>
                <path d="m 2866.3,1430.8 h -4.1 v -30 c 0,-3 -2.4,-5.5 -5.5,-5.5 H 2813 c -3,0 -5.5,2.4 -5.5,5.5 v 30 h -4.1 c -0.8,0 -1.4,0.6 -1.4,1.4 v 1.4 c 0,3 2.4,5.5 5.5,5.5 h 54.6 c 3,0 5.5,-2.4 5.5,-5.5 v -1.4 c 0.1,-0.8 -0.5,-1.4 -1.3,-1.4 z m -56,-30 c 0,-1.5 1.2,-2.7 2.7,-2.7 h 43.7 c 1.5,0 2.7,1.2 2.7,2.7 v 30 h -17.8 -13.7 -17.8 v -30 z m 51.9,35.5 h -54.6 c -1.5,0 -2.7,-1.2 -2.7,-2.7 h 4.1 18.6 l 1,1 c 0.3,0.3 0.6,0.4 1,0.4 h 10.9 c 0.4,0 0.7,-0.1 1,-0.4 l 1,-1 h 18.6 4.1 c -0.2,1.4 -1.5,2.7 -3,2.7 z"/>
                <path d="m 2849.6,1421.9 -1.9,-1.1 c 0,-0.4 0,-0.9 0,-1.3 l 1.9,-1.1 c 0.5,-0.3 0.6,-0.8 0.4,-1.3 l -2.2,-3.7 c -0.3,-0.5 -0.8,-0.6 -1.3,-0.4 l -1.9,1.1 c -0.4,-0.3 -0.7,-0.5 -1.1,-0.7 v -2.2 c 0,-0.5 -0.4,-1 -1,-1 h -4.3 c -0.5,0 -1,0.4 -1,1 v 2.2 c -0.4,0.2 -0.8,0.4 -1.1,0.7 l -1.9,-1.1 c -0.5,-0.3 -1,-0.1 -1.3,0.3 l -2.2,3.7 c -0.3,0.5 -0.1,1 0.4,1.3 l 1.9,1.1 c 0,0.4 0,0.9 0,1.3 l -1.9,1.1 c -0.5,0.3 -0.6,0.8 -0.4,1.3 l 2.2,3.7 c 0.3,0.5 0.8,0.6 1.3,0.4 l 1.9,-1.1 c 0.4,0.3 0.7,0.5 1.1,0.7 v 2.2 c 0,0.5 0.4,1 1,1 h 4.3 c 0.5,0 1,-0.4 1,-1 v -2.2 c 0.4,-0.2 0.8,-0.4 1.1,-0.7 l 1.9,1.1 c 0.5,0.3 1,0.1 1.3,-0.3 l 2.2,-3.7 c 0.3,-0.4 0.1,-1 -0.4,-1.3 z m -9.2,2.2 c -2.2,0 -3.9,-1.8 -3.9,-3.9 0,-2.1 1.8,-3.9 3.9,-3.9 2.2,0 3.9,1.8 3.9,3.9 0,2.1 -1.8,3.9 -3.9,3.9 z"/>
                <path d="m 2832.9,1412.3 1.7,-3 c 0.3,-0.5 0.1,-1 -0.4,-1.3 l -1.4,-0.8 c 0,-0.3 0,-0.6 0,-0.8 l 1.4,-0.8 c 0.5,-0.3 0.6,-0.8 0.4,-1.3 l -1.7,-3 c -0.3,-0.5 -0.8,-0.6 -1.3,-0.3 l -1.4,0.8 c -0.2,-0.2 -0.5,-0.3 -0.7,-0.4 v -1.6 c 0,-0.5 -0.4,-1 -1,-1 h -3.4 c -0.5,0 -1,0.4 -1,1 v 1.6 c -0.2,0.1 -0.5,0.3 -0.7,0.4 l -1.4,-0.8 c -0.5,-0.3 -1,-0.1 -1.3,0.3 l -1.7,3 c -0.3,0.5 -0.1,1 0.4,1.3 l 1.4,0.8 c 0,0.3 0,0.6 0,0.8 l -1.4,0.8 c -0.5,0.3 -0.6,0.8 -0.4,1.3 l 1.7,3 c 0.3,0.5 0.8,0.6 1.3,0.3 l 1.4,-0.8 c 0.2,0.2 0.5,0.3 0.7,0.4 v 1.6 c 0,0.5 0.4,1 1,1 h 3.4 c 0.5,0 1,-0.4 1,-1 v -1.6 c 0.2,-0.1 0.5,-0.3 0.7,-0.4 l 1.4,0.8 c 0.5,0.4 1.1,0.2 1.3,-0.3 z m -6.1,-2.1 c -1.8,0 -3.3,-1.5 -3.3,-3.3 0,-1.8 1.5,-3.3 3.3,-3.3 1.8,0 3.3,1.5 3.3,3.3 0,1.8 -1.4,3.3 -3.3,3.3 z"/>
            </g>
        </switch>
    </svg>
</template>
<style scoped>
    .online-workshop-icon {
        fill: currentColor;
    }
</style>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
    },
}
</script>