<template>
    <div class="user" v-if="ready">
        <v-card elevation="4" class="mb-4" :color="isThemeAja ? '#1E3945' : color">
            <v-card-text>
                <v-row align="center" class="headline" no-gutters>
                    <v-col cols="auto"><v-icon x-large class="mr-5">account_circle</v-icon></v-col>
                    <v-col class="font-weight-bold white--text">{{ user.complete_name }}</v-col>
                    <v-col cols="auto">
                        <template v-if="user.id == $store.getters.getProfile.id">
                            <v-btn
                                :color="color"
                                :to="{name: 'profile-edit'}"
                                class="mr-2 elevation-0"
                                fab
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row>
            <v-col cols="12">
                <v-card elevation="4" :color="isThemeAja ? '#1E3945' : ''">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Nom</v-list-item-title>
                                        <v-list-item-subtitle>{{ value.lastname }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Prénom</v-list-item-title>
                                        <v-list-item-subtitle>{{ value.firstname }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Email</v-list-item-title>
                                        <v-list-item-subtitle><a :style="{ color: isThemeAja ? 'white' : '' }" :href="`mailto:${value.email}`">{{ value.email }}</a></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line v-if="value.role">
                                    <v-list-item-content>
                                        <v-list-item-title>Abonnement en cours</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <template v-if="user.premium_dates">
                                                {{ $moment(user.premium_dates['start']).format('DD/MM/YYYY') }} - {{ $moment(user.premium_dates['end']).format('DD/MM/YYYY') }}
                                            </template>
                                            <template v-else>
                                                Aucun
                                            </template>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>

                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Date de naissance</v-list-item-title>
                                        <v-list-item-subtitle>{{ $moment(value.birthdate).format('DD/MM/YYYY') }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Adresse</v-list-item-title>
                                        <v-list-item-subtitle>{{ value.street }} <br> {{ value.zip }} {{ value.city }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Téléphone</v-list-item-title>
                                        <v-list-item-subtitle><a :style="{ color: isThemeAja ? 'white' : '' }" :href="`tel:${value.phone}`">{{ value.phone }}</a></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        value: {
            type: Object,
            required: true
        },
        color: {
            type: String,
            default: 'primary'
        }
    },
    data(){
        return {
            ready: false,
            user: {},
        }
    },
    computed: {
        ...mapGetters(['isThemeAja']),
    },
    mounted() {
        this.user = Object.assign({}, this.value)
        this.ready = true
    },
    methods: {
        // 
    }
}
</script>
