<template>
    <svg
        class="remote-workshop-icon" 
        :style="{'width': width, 'height': height}"
        x="0px"
        y="0px"
        viewBox="0 0 149.79659 99.999997"
    >
        <switch transform="matrix(2.283105,0,0,2.283105,-6397.2602,-3185.6164)">
            <g>
                <path d="m 2866.3,1430.8 h -4.1 v -30 c 0,-3 -2.4,-5.5 -5.5,-5.5 H 2813 c -3,0 -5.5,2.4 -5.5,5.5 v 30 h -4.1 c -0.8,0 -1.4,0.6 -1.4,1.4 v 1.4 c 0,3 2.4,5.5 5.5,5.5 h 54.6 c 3,0 5.5,-2.4 5.5,-5.5 v -1.4 c 0.1,-0.8 -0.5,-1.4 -1.3,-1.4 z m -56,-30 c 0,-1.5 1.2,-2.7 2.7,-2.7 h 43.7 c 1.5,0 2.7,1.2 2.7,2.7 v 30 h -17.8 -13.7 -17.8 v -30 z m 51.9,35.5 h -54.6 c -1.5,0 -2.7,-1.2 -2.7,-2.7 h 4.1 18.6 l 1,1 c 0.3,0.3 0.6,0.4 1,0.4 h 10.9 c 0.4,0 0.7,-0.1 1,-0.4 l 1,-1 h 18.6 4.1 c -0.2,1.4 -1.5,2.7 -3,2.7 z"/>
                <path d="m 2844,1398.6 c -4.6,0 -8.3,3 -8.3,6.5 0,1.5 0.7,2.9 1.8,4 v 3.3 c 0,0.3 0.2,0.7 0.5,0.8 0.3,0.2 0.7,0.1 0.9,0 l 2.9,-1.9 c 0.7,0.2 1.4,0.2 2.1,0.2 4.6,0 8.3,-2.9 8.3,-6.5 0.1,-3.5 -3.7,-6.4 -8.2,-6.4 z m -3.7,7.4 c -0.5,0 -0.9,-0.4 -0.9,-0.9 0,-0.5 0.4,-0.9 0.9,-0.9 0.5,0 0.9,0.4 0.9,0.9 0,0.5 -0.4,0.9 -0.9,0.9 z m 3.7,0 c -0.5,0 -0.9,-0.4 -0.9,-0.9 0,-0.5 0.4,-0.9 0.9,-0.9 0.5,0 0.9,0.4 0.9,0.9 0,0.5 -0.4,0.9 -0.9,0.9 z m 3.6,0 c -0.5,0 -0.9,-0.4 -0.9,-0.9 0,-0.5 0.4,-0.9 0.9,-0.9 0.5,0 0.9,0.4 0.9,0.9 0,0.5 -0.3,0.9 -0.9,0.9 z"/>
                <path d="m 2829.1,1418.9 c 3.1,0 5.6,-2.5 5.6,-5.5 0,-3.1 -2.5,-5.5 -5.6,-5.5 -3.1,0 -5.6,2.5 -5.6,5.5 0,3.1 2.6,5.5 5.6,5.5 z"/>
                <path d="m 2821.7,1430.1 h 14.9 c 0.5,0 0.9,-0.4 0.9,-0.9 v -3.6 c 0,-2.7 -1.4,-5.2 -3.4,-6.7 -1.3,1.2 -3.1,1.9 -5,1.9 -1.9,0 -3.7,-0.7 -5,-1.9 -2.1,1.5 -3.4,3.9 -3.4,6.7 v 3.6 c 0.1,0.5 0.5,0.9 1,0.9 z"/>
            </g>
        </switch>
    </svg>
</template>
<style scoped>
    .remote-workshop-icon {
        fill: currentColor;
    }
</style>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
    },
}
</script>