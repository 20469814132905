<template>
    <div class="password-edit">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row justify="center" class="mt-5">
                    <v-col cols="12" :md="6">
                        <v-card>
                            <CardTitle text="Modification du mot de passe"/>
                            <v-card-text>
                                <v-form
                                    ref="form"
                                    v-on:submit.prevent="edit"
                                >
                                    <v-text-field
                                        v-model="email"
                                        label="E-mail"
                                        :rules="[rules.required, rules.email]"
                                        outlined
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="password"
                                        label="Mot de passe"
                                        :rules="[rules.required, rules.minlength]"
                                        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                        :type="showPassword ? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword"
                                        hint="8 caractères min."
                                        counter
                                        outlined
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="password_confirmation"
                                        label="Confirmation du mot de passe"
                                        :rules="[rules.required]"
                                        :append-icon="showPasswordConfirm ? 'visibility' : 'visibility_off'"
                                        :type="showPasswordConfirm ? 'text' : 'password'"
                                        @click:append="showPasswordConfirm = !showPasswordConfirm"
                                        outlined
                                    ></v-text-field>

                                    <v-btn
                                        block
                                        color="primary"
                                        type="submit"
                                        :disabled="loading" 
                                        :loading="loading"
                                    >Enregistrer</v-btn>

                                </v-form>

                                <v-btn
                                    block
                                    text
                                    small
                                    color="secondary"
                                    :to="{ name: 'login' }"
                                    class="mt-4"
                                >Se connecter</v-btn>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                email: '',
                password: '',
                password_confirmation: '',
                showPassword: false,
                showPasswordConfirm: false,
                rules: {
                    required: value => !!value || 'Champ requis',
                    minlength: value => value.length >= 8 || '8 caractères min.',
                    email: value => /.+@.+\..+/.test(value) || 'Format invalide',
                },
                loading: false,
            }
        },
        methods: {
            edit: function () {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    const credentials = {
                        email: this.email,
                        token: this.$route.params.token,
                        password: this.password,
                        password_confirmation: this.password_confirmation,
                    }

                    this.$store.dispatch('editPassword', credentials)
                    .then(() => {
                        this.loading = false
                        this.$router.push('/login')
                    })
                    .catch((err) => {
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>
