<template>
    <v-app id="app" :style="isThemeAja ? { backgroundColor: '#294D5B' } : {}" :class="isThemeAja ? 'theme-aja' : ''">
        <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
        <Snackbar />
        <router-view />
    </v-app>
</template>

<style lang="sass">
@import "~@/styles/style.scss"
</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'app',
    computed: {
        ...mapGetters(['isThemeAja']),
    },
    mounted () {
        this.checkAndSetTheme()
    },
    methods: {
        ...mapActions(['setThemeAja']),
        checkAndSetTheme() {
            this.setThemeAja(this.$store.getters.getProfile && this.$store.getters.getProfile.is_aja);
        },
    }
}

</script>

<style>
.theme-aja {
    --v-primary-base: #4E983C;
}
</style>
