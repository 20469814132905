<template>
    <svg
        class="onsite-workshop-icon" 
        :style="{'width': width, 'height': height}"
        x="0px"
        y="0px"
        viewBox="0 0 150 99.999997"
    >
        <switch transform="matrix(2.3255814,0,0,2.3255814,-6516.8605,-3246.9768)">
            <g>
                <path d="m 2852.3,1426.3 c -0.6,-0.3 -1.4,-0.1 -1.7,0.5 -0.3,0.6 -0.1,1.4 0.5,1.7 1.5,0.8 2.4,1.7 2.4,2.5 0,0.9 -1.3,2.4 -5.1,3.7 -3.7,1.3 -8.6,1.9 -13.9,1.9 -5.3,0 -10.2,-0.7 -13.9,-1.9 -3.7,-1.3 -5.1,-2.7 -5.1,-3.7 0,-0.8 0.9,-1.7 2.4,-2.5 0.6,-0.3 0.8,-1.1 0.5,-1.7 -0.3,-0.6 -1.1,-0.8 -1.7,-0.5 -1.7,0.9 -3.7,2.5 -3.7,4.7 0,1.7 1.2,4.2 6.8,6.1 3.9,1.3 9.2,2.1 14.7,2.1 5.5,0 10.8,-0.7 14.7,-2.1 5.6,-1.9 6.8,-4.3 6.8,-6.1 0,-2.2 -2,-3.8 -3.7,-4.7 z m 0,0"/>
                <path d="m 2825,1432.9 c 2.6,0.7 5.9,1.1 9.5,1.1 3.6,0 7,-0.4 9.5,-1.1 3.1,-0.9 4.7,-2.2 4.7,-3.8 0,-1.6 -1.6,-2.9 -4.7,-3.8 -0.7,-0.2 -1.4,-0.4 -2.3,-0.5 -0.4,0.8 -0.9,1.5 -1.4,2.3 0.9,0.1 1.7,0.3 2.5,0.5 2.3,0.6 3.1,1.2 3.3,1.5 -0.2,0.3 -1,0.9 -3.3,1.5 -2.2,0.6 -5,0.9 -7.9,0.9 -0.1,0 -0.3,0 -0.4,0 -0.1,0 -0.3,0 -0.4,0 -3,0 -5.8,-0.3 -7.9,-0.9 -2.3,-0.6 -3.1,-1.2 -3.3,-1.5 0.2,-0.3 1,-0.9 3.3,-1.5 0.8,-0.2 1.6,-0.4 2.5,-0.5 -0.5,-0.8 -0.9,-1.6 -1.4,-2.3 -0.8,0.1 -1.6,0.3 -2.3,0.5 -3.1,0.9 -4.7,2.2 -4.7,3.8 0,1.6 1.6,2.9 4.7,3.8 z m 0,0"/>
                <path d="m 2834.5,1429 c 1.1,0 2.1,-0.6 2.7,-1.5 4.1,-6.7 9,-15.5 9,-19.6 0,-6.5 -5.3,-11.7 -11.7,-11.7 -6.4,0 -11.7,5.3 -11.7,11.7 0,4 4.9,12.9 9,19.6 0.6,0.9 1.6,1.5 2.7,1.5 z m -4.7,-21.9 c 0,-2.6 2.1,-4.7 4.7,-4.7 2.6,0 4.7,2.1 4.7,4.7 0,2.6 -2.1,4.7 -4.7,4.7 -2.6,0 -4.7,-2.1 -4.7,-4.7 z m 0,0"/>
            </g>
        </switch>
    </svg>
</template>
<style scoped>
    .onsite-workshop-icon {
        fill: currentColor;
    }
</style>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
    },
}
</script>