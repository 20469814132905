import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './../router'
import store from './../vuex/store'
 
Vue.use(AclInstaller)

export default new AclCreate({
    initial: 'notAuth',
    notfound: {
        path: '/'
    },
    router,
    acceptLocalRules: true,
    globalRules: {
        isProPremium: new AclRule('pro_premium').generate(),
        isPro: new AclRule('pro').or('pro_premium').generate(),
        isUserPremium: new AclRule('user_premium').generate(),
        isUser: new AclRule('user').or('user_premium').generate(),
        isAuth: new AclRule('pro').or('user').or('pro_premium').or('user_premium').generate(),
        isNotAuth: new AclRule('notAuth').generate(),
    },
    middleware: async acl => {
        let profile = store.getters.getProfile
        if (profile.id) {
            if (profile.types.indexOf('premium') >= 0)
                return acl.change(profile.role+'_premium')
            return acl.change(profile.role)
        }
        return acl.change('notAuth')
    }
})