"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../vuex/store'
import router from '../router'

let config = {
  baseURL: process.env.VUE_APP_API_URL || ""
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        if(localStorage.getItem('access_token')){
            config.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
        }     
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        // Do something with response error
        // if(localStorage.getItem('expires_at') && Date.now() > localStorage.getItem('expires_at')){
        //     store.dispatch('authRefreshRequest')
        //     .then(() => {
        //         router.go(0)
        //     }).catch(() => {
        //         store.commit('setSnack', {'type': 'success', 'msg': 'Vous êtes déconnecté'});
        //         router.push('/home')
        //     })
        // }else{
            if (error.response && error.response.status == 401 && store.getters.isAuthenticated) {
                localStorage.removeItem('access_token')
                store.commit('authLogout');
                store.commit('profileRemove');
                store.commit('setSnack', {'type': 'success', 'msg': 'Vous êtes déconnecté'});
                router.push('/login')
            }
            return Promise.reject(error);
        // }
    }
);

Plugin.install = function(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
