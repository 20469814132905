<template>
    <v-form v-on:submit.prevent="() => ({})" ref="form" class="mb-5">
        <v-card :style="isAja ? { backgroundColor: '#1E3945' } : {}" flat>
            <v-card-title class="justify-center">
                {{ value.name }}
            </v-card-title>
            <v-card-text>
                <div v-for="(question, index) in value.survey_questions" :key="question.id">
                    <div :style="{ color: isAja ? 'white' : 'primary' }" class="font-weight-bold body-1">
                        {{ question.label }}
                    </div>

                    <div v-if="question.type === 'select'" class="mt-0 mb-7">
                        <v-select
                            :color="isAja ? '#4E983C' : ''"
                            v-model="question.response"
                            :items="question.choices"
                            outlined
                            :rules="question.required ? [rules.required] : []"
                            hide-details
                            :menu-props="{maxHeight: '100%'}"
                        ></v-select>
                        <v-text-field
                            :color="isAja ? '#4E983C' : ''"
                            v-if="question.other && question.response === 'Autre'"
                            v-model="question.otherResponse"
                            outlined
                            hide-details
                            class="mt-1"
                            placeholder="..."
                        ></v-text-field>
                    </div>

                    <v-select
                        :color="isAja ? '#4E983C' : ''"
                        v-if="question.type === 'multiple_select'"
                        v-model="question.response"
                        :items="question.choices"
                        outlined
                        multiple
                        chips
                        deletable-chips
                        :rules="question.required ? [rules.required] : []"
                        hide-details
                        class="mt-0 mb-7"
                        :menu-props="{maxHeight: '100%', top: true, offsetY: true}"
                    ></v-select>

                    <v-autocomplete
                        :color="isAja ? '#4E983C' : ''"
                        v-if="question.type === 'autocomplete'"
                        v-model="question.response"
                        :items="question.choices"
                        outlined
                        :rules="question.required ? [rules.required] : []"
                        hide-details
                        class="mt-0 mb-7"
                        :menu-props="{
                            maxHeight: '100%', 
                            value: false,
                            closeOnClick: true,
                            closeOnContentClick: true,
                        }"
                        hide-no-data
                        no-data-text="Aucune donnée trouvée"
                        clearable
                        :ref="`autocomplete_${question.id}`"
                        :filter="filterAutocomplete"
                        @update:search-input="(item) => updateAutocomplete(question.id, item)"
                        @click.stop
                    ></v-autocomplete>

                    <v-text-field
                        :color="isAja ? '#4E983C' : ''"
                        v-if="question.type === 'partner' && question.partner_type === 'healthcenter'"
                        v-model="zipPartner"
                        outlined
                        maxlength="2"
                        hide-details
                        class="mt-0 mb-7"
                        label="Département (ex: 33)"
                        @input="filterHealthcenters(index)"
                    ></v-text-field>

                    <div v-if="question.type === 'partner' && question.choices">
                        <v-autocomplete
                            :color="isAja ? '#4E983C' : ''"
                            v-if="question.partner_type === 'healthcenter'"
                            v-model="question.response"
                            :items="filteredHeathcenters.map(p => ({text: p.name, value: p.id, zip: p.zip}))"
                            outlined
                            :rules="question.required ? [rules.required] : []"
                            hide-details
                            class="mt-0 mb-7"
                            hide-no-data
                            no-data-text="Aucune donnée trouvée"
                            clearable
                            ref="autocompleteHealthCenters"
                            :disabled="zipPartner.length < 2"
                            :placeholder="filteredHeathcentersPlaceholder"

                        ></v-autocomplete>
                        <v-autocomplete
                            v-else
                            :color="isAja ? '#4E983C' : ''"
                            v-model="question.response"
                            :items="question.choices ? question.choices.map(p => ({text: p.name, value: p.id, zip: p.zip})) : []"
                            outlined
                            :rules="question.required ? [rules.required] : []"
                            hide-details
                            class="mt-0 mb-7"
                            :menu-props="{
                                maxHeight: '100%',
                                value: false,
                                closeOnClick: true,
                                closeOnContentClick: true,
                            }"
                            hide-no-data
                            no-data-text="Aucune donnée trouvée"
                            clearable
                            :ref="`autocomplete_${question.id}`"
                            :filter="filterPartner"
                            @update:search-input="(item) => updateAutocomplete(question.id, item, 1)"
                            @click.stop
                        ></v-autocomplete>

                    </div>

                    <div v-if="question.type === 'radio'" class="mt-0 mb-7">
                        <v-radio-group
                            v-model="question.response"
                            row
                            :rules="question.required ? [rules.required] : []"
                            hide-details
                        >
                            <v-radio
                                v-for="choice in question.choices"
                                :key="choice"
                                :label="choice"
                                :value="choice"
                                :ripple="false"
                                :color="isAja ? '#4E983C' : ''"
                            ></v-radio>

                        </v-radio-group>
                    </div>

                    <!-- checkbox -->
                    <div v-if="question.type === 'checkbox' && question.choices && question.choices.length > 1"
                         class="mt-0 mb-7">
                        <v-checkbox
                            v-model="question.response"
                            v-for="choice in question.choices"
                            :key="choice"
                            outlined
                            :rules="question.required ? [rules.required] : []"
                            hide-details
                            :label="choice"
                            :color="isAja ? '#4E983C' : ''"
                        ></v-checkbox>
                    </div>
                    <div v-if="question.type === 'checkbox' && question.choices && question.choices.length === 1"
                         class="mt-0 mb-7">
                        <v-checkbox
                            v-model="question.response"
                            outlined
                            :rules="question.required ? [rules.required] : []"
                            hide-details
                            :color="isAja ? '#4E983C' : ''"
                        ></v-checkbox>
                    </div>
                    <div v-if="question.type === 'date'">
                        <date-input
                            :color="isAja ? '#4E983C' : ''"
                            :key="$moment() + question.id"
                            v-model="question.response"
                            @save="date => question.response = date"
                            :required="!!question.required"
                            format="YYYY-MM"
                            label=""
                        />
                    </div>

                    <v-text-field
                        :color="isAja ? '#4E983C' : ''"
                        v-if="question.type === 'string'"
                        v-model="question.response"
                        outlined
                        :rules="question.required ? [rules.required] : []"
                        hide-details
                        class="mt-0 mb-7"
                    ></v-text-field>

                    <v-combobox
                        v-if="question.type === 'combobox'"
                        v-model="question.response"
                        outlined
                        multiple
                        chips
                        deletable-chips
                        :rules="question.required ? [rules.required] : []"
                        class="mt-0 mb-7"
                        :menu-props="{maxHeight: '100%'}"
                        hint="taper sur Entrée ou Tab pour ajouter une réponse"
                        persistent-hint
                        :color="isAja ? '#4E983C' : ''"
                    ></v-combobox>

                    <v-textarea
                        :color="isAja ? '#4E983C' : ''"
                        v-if="question.type === 'textarea'"
                        v-model="question.response"
                        outlined
                        :rules="question.required ? [rules.required] : []"
                        hide-details
                        class="mt-0 mb-7"
                        auto-grow
                        rows="1"
                    ></v-textarea>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn :color="isAja ? '#4E983C' : 'primary'" dark @click="onSubmit" :disabled="loading"
                       :loading="loading">Envoyer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<style scoped>
.v-input--radio-group >>> .v-input--radio-group__input {
    justify-content: center !important;
}
</style>
<script>
export default {
    props: {
        value: Object,
        from_registration: {
            type: Boolean,
            required: false
        }
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || 'Champ requis'
            },
            zipPartner: '',
            filteredHeathcenters: [],
            filteredHeathcentersPlaceholder: ''
        }
    },
    mounted () {
        if (this.value?.survey_questions?.length) {
            for (let i = 0; i < this.value.survey_questions.length; i++) {
                const q = this.value.survey_questions[i]
                if (q.partner_type === 'healthcenter') {
                    let partner = q.choices.find(p => p ? p.id == q.response : null)
                    if (partner) {
                        this.zipPartner = partner.zip.slice(0, 2)
                        this.filterHealthcenters(i)
                    }
                }
            }
        }
    },
    computed: {
        isAja () {
            return this.$store.getters.getProfile.is_aja
        }
    },
    watch: {
        value () {
            (this.value.survey_questions || []).forEach((q) => {
                if (q.other && !q.type === 'partner') q.choices.push('Autre')
            })
        }
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true

                let queries = {
                    user_id: this.$store.getters.getProfile.id,
                    survey_id: this.value.id,
                    from_registration: this.from_registration,
                    survey_responses: this.value.survey_questions.map((q) => {
                        if (q.response === 'Autre' && q.otherResponse) q.response = q.otherResponse
                        return { survey_question_id: q.id, response: q.response || null }
                    })
                }

                this.$store.dispatch('surveySessionCreateRequest', queries)
                    .then((survey_session) => {
                        this.$refs.form.resetValidation()
                        this.$store.commit('setSnack', { 'type': 'success', 'msg': 'Réponses enregistrées !' })
                        this.$emit('saved', survey_session)
                    })
                    .catch((err) => {
                        if (err.response.status !== 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        filterAutocomplete (item, queryText, itemText) {
            if (queryText.length < 3) return false
            return itemText.toString()
                .toLowerCase()
                .indexOf(queryText.toString().toLowerCase()) > -1
        },
        updateAutocomplete (question_id, item, minLength = 3) {
            if (this.$refs[`autocomplete_${question_id}`]) {
                let input = this.$refs[`autocomplete_${question_id}`][0]
                if (!item || item.length < minLength)
                    input.$_menuProps.value = false
                else if (input.isFocused) input.$_menuProps.value = true
            }
        },
        filterPartner (item, queryText, itemText) {
            if (queryText.length < 1) return false

            let initialCondition = itemText.toString()
                .toLowerCase()
                .indexOf(queryText.toString().toLowerCase()) > -1

            return initialCondition
        },
        filterHealthcenters (index) {
            if (this.zipPartner.length === 2) {
                this.filteredHeathcenters = this.value.survey_questions[index].choices.filter(choice => choice.zip != null && choice.zip.startsWith(this.zipPartner.substring(0, 3)))
                this.filteredHeathcentersPlaceholder = 'Séléctionnez votre centre de soin'
            }
        }
    }
}
</script>
