import Vue from 'vue'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import './plugins/moment'
import acl from './plugins/acl'
import App from './App.vue'
import router from './router'
import store from './vuex/store'


import Navigation from './components/Navigation.vue'
Vue.component('Navigation', Navigation)

import Snackbar from './components/Snackbar.vue'
Vue.component('Snackbar', Snackbar)

import ConfirmButton from './components/ConfirmButton.vue'
Vue.component('ConfirmButton', ConfirmButton)

import Footer from './components/Footer.vue'
Vue.component('my-footer', Footer)

import OncogiteBrand from './components/OncogiteBrand.vue'
Vue.component('oncogite', OncogiteBrand)

import CardTitle from './components/CardTitle.vue'
Vue.component('CardTitle', CardTitle)

import NotPremiumCard from './components/NotPremiumCard.vue'
Vue.component('NotPremiumCard', NotPremiumCard)

import DateInput from './components/DateInput.vue'
Vue.component('date-input', DateInput)

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    acl,
    store,
    render: h => h(App)
}).$mount('#app')
