<template>
    <div class="workshop">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row>
                    <v-col cols="12" v-if="loading && !isThemeAja">
                        <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" v-else>
                        <WorkshopDetails :color="isThemeAja ? '#4E983C' : 'primary'" ref="WorkshopDetails" v-model="workshop" v-if="workshop.id"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import WorkshopDetails from '@/components/workshops/Details'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        WorkshopDetails,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            workshop: {},
            loading: false,
        }
    },
    mounted () {
        this.getWorkshop()
    },
    computed: {
        ...mapGetters(['isThemeAja']),
    },
    
    methods: {
        ...mapActions(['setThemeAja']),
        async getWorkshop() {
            this.loading = true;
            try {
                const result = await this.$store.dispatch('workshopRequest', { id: this.id, with: ['user', 'meetings', 'partner.logo'] });
                this.setThemeAja(result.data.is_aja ? true : false)
                if (await this.isAuthorizedWorkshop(result.data)) {
                    this.workshop = result.data;
                } else {
                    this.$store.dispatch('setErrors', "Vous n'êtes pas autorisé à accéder à cet atelier.");
                    this.redirectUser()
                }
            } catch (err) {
                if (err.response && err.response.status !== 403) {
                    this.$store.dispatch('setErrors', err.response.data);
                }
            } finally {
                this.loading = false;
            }
        },
        async isAuthorizedWorkshop(workshop) {
            const profile = this.$store.getters.getProfile;
            
            if(!profile.id) {
                return true
            }
            if (workshop.is_aja === profile.is_aja) {
                return true;
            }
            
            return false;
        },
        redirectUser() {
            const profile = this.$store.getters.getProfile;
            this.$router.push({name: profile.is_aja ? 'aja-home' : 'Workshops'});
        }
    }
}
</script>
