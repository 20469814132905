<template>
    <div v-if="this.profile">
        <v-btn v-if="QrInfoAlert" :to="{ name: 'ProfileSurvey' }" icon small color="primary" class="mr-12"><v-icon
                size="70">warning</v-icon></v-btn>
        <v-btn v-else-if="QrSatAlert" :to="{ name: 'SatisfactionSurvey' }" icon small
            :color="$vuetify.theme.dark ? 'primary' : 'secondary'" class="mr-12"><v-icon
                size="70">help</v-icon></v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            profile: null,
        }
    },
    computed: {
        QrInfoAlert() {
            return this.profile.qr_info_alert
        },
        QrSatAlert() {
            return this.profile.qsat_alert
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        getUser() {
            this.$store.dispatch('userRequest', { id: this.$store.getters.getProfile.id })
                .then(result => {
                    this.profile = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
    }

}
</script>