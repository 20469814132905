import Vue from 'vue'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    resetPassword: ({commit}, credentials) => {
        let data = {'email': credentials.email}

        return new Promise((resolve, reject) => {
            Vue.axios.post('api/password/email', data)
                .then(result => {
                    commit('setSnack', {'type': 'success', 'msg': 'Demande de réinitialisation envoyée'})
                    resolve()
                })
                .catch(err => {
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
    editPassword: ({commit}, credentials) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post('api/password/reset', credentials)
                .then(result => {
                    commit('setSnack', {'type': 'success', 'msg': 'Mot de passe modifié'})
                    resolve(result)
                })
                .catch(err => {
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    }
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}