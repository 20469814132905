import Vue from 'vue'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    etransactionsCreateRequest: async ({commit}, queries) => {
        let url = `/api/etransactions`;

        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
    paymentPrintDonationRequest: async ({commit}, queries) => {
        let url = `/api/payments/${queries.id}/print/donation`;

        return new Promise((resolve, reject) => {
            Vue.axios.get(url, {responseType: 'blob'})
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': this.$t('unauthorized')})
                    reject(err)
                })
        })
    },
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}