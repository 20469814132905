import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import OnsiteWorkshop from '@/components/icons/OnsiteWorkshop'
import RemoteWorkshop from '@/components/icons/RemoteWorkshop'
import OnlineWorkshop from '@/components/icons/OnlineWorkshop'

Vue.use( Vuetify )
 
export default new Vuetify({
    icons: {
        iconfont: 'md',
        values: {
            onsite_workshop: {
                component: OnsiteWorkshop,
            },
            online_workshop: {
                component: OnlineWorkshop,
            },
            remote_workshop: {
                component: RemoteWorkshop,
            },
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: true,
        themes: {
            light: {
                primary: '#f7921d',
                secondary: '#606161'
            },
            dark: {
                primary: '#f7921d',
                secondary: '#606161'
            }
        }
    }
})
