<template>
    <v-footer ref="footer" :fixed="fixed" color="transparent" padless class="text-center justify-center" v-resize="onResize">
        <!-- <div class="headline secondary--text">LES ATELIERS QUI RECONNECTENT LES NEURONES !</div> -->
        <v-img ref="footerImg" :src="require('@/assets/images/bandeau_personnages.png')" max-height="300" contain @load="onResize"></v-img>
    </v-footer>
</template>
<script>
export default {
    data() {
        return {
            fixed: false,
        }
    },
    mounted() {
        // this.onResize()
    },
    methods: {
        onResize () {
            let windowHeight = window.innerHeight
            let contentHeight = this.$refs.footer.$parent.$parent.$el.clientHeight
            let footerHeight = this.$refs.footer.$el.clientHeight
            let contentWithoutFooter = this.fixed ? contentHeight : (contentHeight - footerHeight)

            // console.log(windowHeight, contentHeight, contentWithoutFooter, footerHeight)
            if (windowHeight - contentWithoutFooter > (footerHeight || 300))
                this.fixed = true
            else
                this.fixed = false
        },
    },
}
</script>