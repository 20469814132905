<template>
    <v-card-title>
        <v-row justify="center">
            <v-col cols="12" :class="{'white--text': $vuetify.theme.dark, 'headline text-uppercase mt-2 text-center': true}">{{ text }}</v-col>
        </v-row>

    </v-card-title>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            // 
        }
    },
    mounted() {
        // 
    },
    methods: {
        // 
    },
}
</script>