import Vue from 'vue'

const state = {
  themeAja: false
}

const getters = {
  isThemeAja (state) {
    return state.themeAja
  }
}

const actions = {
  setThemeAja ({ commit }, payload) {
    console.log('set theme aja')
    commit('SET_THEME_AJA', payload)
  },
  userRequest: async ({ commit }, queries) => {
    let url = `/api/users/${queries.id}`

    if (queries.with) {
      let params = '?'
      queries.with.forEach((w, i) => params += (i > 0 ? '&' : '') + 'with[' + i + ']=' + w)
      url += params
    }

    return new Promise((resolve, reject) => {
      Vue.axios.get(url)
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          if (err.response.status === 403)
            commit('setSnack', { 'type': 'red', 'msg': 'Action non autorisée' })
          reject(err)
        })
    })
  },
  userEditRequest: async ({ commit }, queries) => {
    let url = `/api/users/${queries.id}`

    return new Promise((resolve, reject) => {
      Vue.axios.put(url, queries.datas)
        .then(result => {
          resolve(result.data.data)
        })
        .catch(err => {
          if (err.response.status === 403)
            commit('setSnack', { 'type': 'red', 'msg': 'Action non autorisée' })
          reject(err)
        })
    })
  },
  userUpdateRegistrationProcessRequest: async ({ commit }, payload) => {
    let url = `/api/users/${payload.id}/update-registration-process`

    return Vue.axios.put(url, {
      // convert to string if necessary (this should be done server side...!)
      registration_process: typeof payload.data === 'string' ? payload.data : JSON.stringify(payload.data)
    })
      .then(result => {
        commit('profileSuccess', result.data.data)
        return result.data.data
      })
      .catch(err => {
        if (err.response.status === 403)
          commit('setSnack', { 'type': 'red', 'msg': 'Action non autorisée' })
      })
  },
  usersRequest: async ({ commit }, queries) => {
    //remove null values
    queries = Object.entries(queries).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {})
    //build query url
    // let url = '/api/users?'+Object.entries(queries).map(e => e.join('=')).join('&')
    let url = '/api/users?' + Object.entries(queries).map((e) => {
      if (typeof e[1] == 'object') {
        let s = ''
        e[1].forEach((q, i) => s += e[0] + '[' + i + ']=' + q + (i < e[1].length - 1 ? '&' : ''))
        return s
      }
      return e.join('=')
    }).join('&')

    return new Promise((resolve, reject) => {
      Vue.axios.get(url)
        .then(result => {
          resolve(result.data)
        })
        .catch(err => {
          if (err.response.status === 403)
            commit('setSnack', { 'type': 'red', 'msg': 'Action non autorisée' })
          reject(err)
        })
    })
  },
  freeAdhesionRequest:
    async ({ commit }, queries) => {
      let url = `/api/users/free-adhesion`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, queries)
          .then(result => {
            resolve(result.data)
          })
          .catch(err => {
            if (err.response.status === 403)
              commit('setSnack', { 'type': 'red', 'msg': 'Action non autorisée' })
            reject(err)
          })
      })
    },
  ajaAdhesionRequest:
    async ({ commit }, queries) => {
      let url = `/api/users/aja-adhesion`
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, queries)
          .then(result => {
            resolve(result.data)
          })
          .catch(err => {
            if (err.response.status === 403)
              commit('setSnack', { 'type': 'red', 'msg': 'Action non autorisée' })
            reject(err)
          })
      })
    }
}

const mutations = {
  SET_THEME_AJA (state, payload) {
    localStorage.setItem('theme.dark', true)
    state.themeAja = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
