import Vue from 'vue'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    partnerRequest: async ({commit}, queries) => {
        let url = `/api/partners/${queries.id}`;

        if (queries.with) {
            let params = '?'
            queries.with.forEach((w, i) => params += (i > 0 ? '&' : '')+'with['+i+']='+w)
            url += params
        }

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
    partnerCheckAdhSecretCode: ({commit}, payload) => {
        let actionUrl = '/api/partners/check-adh-code';
        let data = {
            'adh_secret_code': payload.adh_secret_code,
            'partner_id': payload.id,
            'partner_zip': payload.zip,
            'user_id': JSON.parse(localStorage.getItem('profile')).id
        }   
        return new Promise((resolve, reject) => {
            Vue.axios.post(actionUrl, data)
                .then(async (resp) => {
                    resolve(resp.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    partnersRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/partners?'+Object.entries(queries).map((e) => {
            if (typeof e[1] == 'object') {
                let s = ''
                e[1].forEach((q, i) => s += e[0]+'['+i+']='+q+(i<e[1].length-1 ? '&' : ''))
                return s
            }
            return e.join('=')
        }).join('&')

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}