<template>
    <v-card>
        <!-- <CardTitle text="Conditions générales"/> -->
        <v-card-title class="justify-end" v-if="closable">
            <v-btn icon @click="$emit('close')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <div class="body-1 text-justify">
                <h1 class="my-5 pt-5 text-center">Charte de confidentialité</h1>
                <h2 class="my-5 pt-5 text-center">Charte relative à la protection des données à caractère personnel des utilisateurs</h2>

                <h2 class="my-5 pt-5">Sommaire</h2>
                <p>
                    <ol>
                        <li>
                            Définition et nature des données à caractère personnel
                            <ol type="a">
                                <li>Information que vous nous communiquez</li>
                                <li>Information que nous collectons automatiquement lors de votre utilisation de la Plateforme</li>
                            </ol>
                        </li>
                        <li>Objet de la présente charte</li>
                        <li>Identité du responsable de la collecte et du traitement de données</li>
                        <li>Collecte et traitement des données à caractère personnel</li>
                        <li>Destinataires des données collectées et traitées</li>
                        <li>Cession des données à caractère personnel</li>
                        <li>Durée de conservation des données à caractère personnel</li>
                        <li>Sécurité</li>
                        <li>Hébergement</li>
                        <li>Cookies</li>
                        <li>Consentement</li>
                        <li>Accès à vos données à caractère personnel</li>
                        <li>Portabilité de vos données à caractère personnel</li>
                        <li>Introduction d’une réclamation devant une autorité de contrôle</li>
                        <li>Communication relative à une violation de données à caractère personnel</li>
                        <li>Modifications</li>
                        <li>Entrée en vigueur</li>
                    </ol>
                </p>

                <h2 class="my-5 pt-5">1 - Définition et nature des données à caractère personnel</h2>
                <p>Lors de votre utilisation du site internet accessible à l’adresse www.oncogite.com (ci-après la "Plateforme"), qui permet à des patients et des professionnels d’organiser des ateliers de remédiation cognitive, nous pouvons être amenés à vous demander de nous communiquer des données à caractère personnel vous concernant, désignant toutes les données qui permettent d’identifier un individu directement ou indirectement.</p>
                <p>Dans ce cadre, nous collectons les données suivantes :</p>

                <h3 class="my-3 pt-3">1.1 - Information que vous nous communiquez</h3>
                <p>Pour la bonne exécution des services que nous proposons à travers la Plateforme, et notamment les services de paiement, nous collectons les données suivantes :</p>
                <p>Pour les patients.es :</p>
                    <ul>
                        <li>informations permettant votre identification lors de l’ouverture de votre compte ou de la réalisation d’un paiement : nom, prénom, adresse e-mail, adresse postale, nationalité, date de naissance ;</li>
                        <li>informations nécessaires à la vérification de votre identité lors des opérations de paiement lorsque le Contributeur réalise un paiement supérieur à 2.500 € : copie de votre carte d’identité, justificatif de domicile ;</li>
                        <li>informations relatives au paiement : numéros de carte bancaire, numéros BIC et IBAN en cas de prélèvement SEPA ;</li>
                        <li>toutes informations que vous choisirez de nous communiquer : et notamment, un questionnaire d’autoévaluation, une photographie de profil, toute information d’ordre médical (pathologie, traitement suivi) nous permettant de vous proposer des ateliers adaptés à vos besoins  </li>
                    </ul>

                <p>Pour les professionnels (neuropsychologues, instituts…) :</p>
                <ul>
                    <li>informations permettant votre identification lors de l’ouverture de votre compte : nom, prénom, adresse e-mail, adresse postale , n° ADELI, qualification, n° de téléphone</li>
                    <li>informations relatives au paiement : numéros de carte bancaire, numéros BIC et IBAN en cas de prélèvement SEPA , SIRET, URSSAF, N° de SS, relevés d’activité ;</li>
                    <li>informations nécessaires à l’authentification de l’Organisme lors de l’organisation d’ateliers physiques : nom, , adresse, contact e-mail. Nom et prénom, nationalité, date de naissance du représentant légal de l’Organisme.</li>
                    <li>toutes informations que vous choisirez de nous communiquer </li>
                </ul>


                <h3 class="my-3 pt-3">1.2 - Information que nous collectons automatiquement lors de votre utilisation de la Plateforme</h3>
                <p>Lors de votre utilisation de la Plateforme et pour la bonne exécution des services, nous sommes également susceptibles de collecter des données à caractère personnel vous concernant, de manière automatique par l’intermédiaire des outils et services proposés sur la Plateforme.</p>
                <p>Que vous soyez un Patient.e ou un Professionnel.le, nous sommes amenées à collecter les données suivantes :</p>
                <ul>
                    <li>Informations de géolocalisation : lors de votre utilisation de certaines fonctionnalités de la Plateforme, nous pouvons collecter des informations sur votre localisation, notamment grâce à votre adresse IP, le GPS de votre appareil mobile. La majorité de appareils mobiles vous permet de contrôler et de désactiver les fonctions de géolocalisation à partir du menu "paramètres" de votre appareil ;</li>
                    <li>Informations d’utilisation des outils et fonctionnalités de la Plateforme : nous collectons des informations concernant vos interactions avec la Plateforme et notamment, les pages ou les contenus consultés sur la Plateforme, les liens sur lesquels vous avez cliqués ;</li>
                    <li>Informations de connexion et relatives aux équipements et appareils que vous utilisez pour vous connecter à la Plateforme : nous collectons les données de connexion d’appareils lorsque vous accédez et utilisez la Plateforme, y compris si vous n’avez pas ouvert de compte sur notre Plateforme, et notamment, votre adresse IP, les dates et heures de connexion, les données relatives au matériel et aux logiciels informatiques utilisés, les identifiants uniques, les données de plantage, les pages visualisées ou affichées avant ou après s’être connecté à la Plateforme ;</li>
                    <li>Informations sur les opérations de paiement : date et heure du paiement, moyen de paiement utilisé, date d’expiration du moyen de paiement, montant du paiement.</li>
                </ul>

                <h2 class="my-5 pt-5">2 - Objet de la présente charte</h2>
                <p>La présente charte a pour objet de vous informer sur les moyens que nous mettons en œuvre pour collecter et traiter vos données à caractère personnel, dans le respect le plus strict de vos droits.</p>
                <p>Nous vous indiquons à ce sujet que nous nous conformons, dans la collecte et la gestion de vos données à caractère personnel, à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dans sa version actuelle, ainsi qu’au Règlement n°2016/ 679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, dénommé Règlement Général sur la Protection des Données (ci-après "RGPD").</p>

                <h2 class="my-5 pt-5">3 - Identité du responsable de la collecte et du traitement de données</h2>
                <p>Le responsable de la collecte et du traitement de vos données à caractère personnel est l’association <oncogite/>, association loi 1901 enregistrée auprès de la préfecture de Gironde (33) sous le numéroW332027865, dont le siège social est situé 90 rue St Genes 33000 BORDEAUX (dénommée dans le cadre des présentes "Nous").</p>

                <h2 class="my-5 pt-5">4 - Collecte et traitement des données à caractère personnel</h2>
                <p>Vos données à caractère personnel sont collectées et traitées pour répondre à une ou plusieurs des finalités suivantes :</p>
                <ul>
                    <li>Gérer votre accès à la Plateforme et aux services accessibles sur la Plateforme et leur utilisation, ainsi que répondre à toute demande concernant votre utilisation des services,</li>
                    <li>Effectuer les opérations relatives à la gestion et au suivi de nos relations avec les patients.es, Organismes ou professionnels, à l’occasion de l’utilisation de nos services,</li>
                    <li>Constituer un fichier de membres inscrits, d’utilisateurs,</li>
                    <li>Adresser des newsletters ou tous messages informatifs relatifs à notre actualité et/ou toute évolution de nos Services. Dans le cas où vous ne le souhaiteriez pas, nous vous donnons la faculté d’exprimer votre refus à ce sujet lors de la collecte de vos données ;</li>
                    <li>Élaborer des statistiques d’utilisation et de fréquentation de nos services,</li>
                    <li>Optimiser le fonctionnement et l’efficacité des produits et services que nous vous proposons,</li>
                    <li>Gérer la gestion des avis des personnes sur des services ou contenus,</li>
                    <li>Respecter nos obligations légales et réglementaires, notamment en matière de lutte contre la fraude bancaire. A ce titre, nous avons notamment l’obligation de vérifier ou d’authentifier vos informations collectées lors des opérations de paiement.</li>
                </ul>
                <p>Nous vous informons, lors de la collecte de vos données personnelles, si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives. Nous vous indiquons également quelles sont les conséquences éventuelles d’un défaut de réponse.</p>

                <h2 class="my-5 pt-5">5 - Destinataires des données collectées et traitées</h2>
                <p>Le personnel et les membres dirigeants de notre association, les services chargés du contrôle (commissaire aux comptes notamment) et nos sous-traitants peuvent avoir accès à vos données à caractère personnel.</p>
                <p>Nous communiquerons également vos données à caractère personnel aux Institutions (de soins, de malades…) que vous choisirez, par l’intermédiaire de notre Plateforme, pour participer à des ateliers présentiels. L’Institution pourra les utiliser pour les mêmes finalités et sous les mêmes conditions que celles visées dans la présente Charte. Elle reste toutefois seule et unique responsable du respect de ses obligations légales et déclaratives dans le cadre du traitement de vos données à caractère personnel qu’elle effectue par lui-même, avec ses propres moyens et pour ses seuls besoins. Nous ne sommes responsables que de notre utilisation de vos données à caractère personnel, à l’exclusion de toutes autres utilisations effectuées par l’Institution.</p>
                <p>Peuvent également être destinataires de vos données à caractère personnel les organismes publics, exclusivement pour répondre à nos obligations légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés d’effectuer le recouvrement de créances.</p>

                <h2 class="my-5 pt-5">6 - Cession des données à caractère personnel</h2>
                <p>Vos données à caractère personnel ne feront pas l’objet de cessions, locations ou échanges au bénéfice de tiers, à l’exception des Institutions de recherche pour lesquelles vous choisirez d’autoriser l’accès et auxquelles nous communiquerons les données vous concernant, en rapport avec l’exécution de recherches scientifiques, dans le cadre défini par la loi et un protocole de recherche.</p>
                <p>Cependant, vous êtes informés que nous nous réservons la possibilité de communiquer à des tiers vos données entièrement anonymisées et sous forme agrégée, c’est-à-dire dans une forme ne permettant pas de vous identifier, de quelque manière que ce soit. Ces données seront transmises par nos soins de manière collective et non individualisée.</p>

                <h2 class="my-5 pt-5">7 - Durée de conservation des données à caractère personnel</h2>
                <p>Concernant les données relatives à la gestion et au suivi des relations avec les utilisateurs de nos services :</p>
                <p>Vos données à caractère personnel ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de notre relation avec vous. Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, devant être conservées au titre du respect d’une obligation légale, le seront pendant la durée prévue par la loi en vigueur.</p>
                <p>Nous conservons vos données pendant un délai maximum de trois (3) ans à compter de la collecte de vos données ou du dernier contact émanant de l’utilisateur ou de la fermeture de votre compte sur la Plateforme.</p>
                <p>Dans les 3 mois précédant l'échéance de la durée de conservation des données à caractère personnel fixée à 3 ans, nous pourrons reprendre contact avec vous afin que vous nous fassiez part de votre consentement ou non sur le fait de continuer à recevoir des messages informatifs sur nos services.</p>
                <p>Dans le cas de la souscription à nos newsletters, nous conservons les données destinées à nous permettre de vous rendre destinataire des newsletters, dont vous pourrez demander à être désinscrit à tout moment. Chacun de nos e-mails envoyés contient un lien de désinscription sur lequel vous pouvez cliquer pour être retiré de nos listes d’envoi.</p>
                <p>Concernant les données relatives aux cartes bancaires :</p>
                <p>Les transactions financières relatives au paiement des opérations de financement et des frais via la Plateforme, sont confiées à un prestataire de services de paiement qui en assure le bon déroulement et la sécurité.</p>
                <p>Toutes les informations bancaires pour traiter le paiement sont cryptées grâce au protocole SSL.</p>
                <p>Pour les besoins des services, ce prestataire de services de paiement peut être amené à être destinataire de vos données à caractère personnel relatives à vos coordonnées bancaires et notamment vos numéros de cartes bancaires, d’IBAN ou de RIB, qu’il recueille et conserve en notre nom et pour notre compte.</p>
                <p>Nous n’avons pas accès à ces données.</p>
                <p>Le prestataire de services de paiement est seul et unique responsable du respect de ses obligations légales et déclaratives dans le cadre du traitement qu’il effectue de vos données à caractère personnel, avec ses moyens propres et pour ses seuls besoins. </p>
                <p>Pour vous permettre de réaliser régulièrement des opérations financières ou de régler les frais afférents sur la Plateforme, vos données relatives à vos cartes bancaires sont conservées pendant le temps de votre inscription sur la Plateforme et à tout le moins, jusqu’au moment où vous réalisez votre dernière transaction.</p>
                <p>En ayant coché sur la Plateforme la case expressément prévue à cet effet, vous nous donnez votre consentement exprès pour cette conservation.</p>
                <p>Les données relatives au cryptogramme visuel ou CVV2, inscrit sur votre carte bancaire, ne sont pas stockées.</p>
                <p>Si vous refusez que vos données à caractère personnel relatives à vos numéros de cartes bancaires soient conservées dans les conditions précisées ci-dessus, nous ne conserverons pas ces données au-delà du temps nécessaire pour permettre la réalisation de la transaction.</p>
                <p>En tout état de cause, les données relatives à celles-ci pourront être conservées, pour une finalité de preuve en cas d’éventuelle contestation de la transaction, en archives intermédiaires, pour la durée prévue par l’article L 133-24 du Code monétaire et financier, en l’occurrence treize (13) mois suivant la date de débit. Ce délai peut être étendu à quinze (15) mois afin de prendre en compte la possibilité d’utilisation des cartes de paiement à débit différé.</p>
                <p>Concernant la gestion des listes d’opposition à recevoir de la prospection :</p>
                <p>Les informations permettant de prendre en compte votre droit d’opposition sont conservées au minimum trois (3) ans à compter de l’exercice du droit d’opposition.</p>
                <p>Concernant les statistiques de mesure d’audience :</p>
                <p>Les informations stockées dans le terminal des utilisateurs ou tout autre élément utilisé pour identifier les utilisateurs et permettant leur traçabilité ou fréquentation ne seront pas conservées au-delà de treize (13) mois.</p>
                <p>Concernant l’archivage des données relatives aux opérations bancaires :</p>
                <p>A l’issue de la durée de conservation de vos données telle que précisée ci-dessus, nous vous informons que nous archivons vos données pendant un délai de deux (2) ans pour répondre à nos obligations légales, notamment en termes de lutte contre la fraude bancaire et le blanchiment d’argent.</p>

                <h2 class="my-5 pt-5">8 - Sécurité</h2>
                <p>Nous vous informons prendre toutes précautions utiles, mesures organisationnelles et techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos données à caractère personnel et notamment, empêcher qu’elles soient déformées, endommagées ou que des tiers non autorisés y aient accès.</p>

                <h2 class="my-5 pt-5">9 - Hébergement</h2>
                <p>Nous vous informons que vos données sont conservées et stockées, pendant toute la durée de leur conservation sur les serveurs de la société OVH, située en France.</p>
                <p>L’ordonnance du 12 janvier 2017, adoptée avec le décret n°2018-137 soumet au dispositif de certification des hébergeurs de données de santé (HDS) « toute personne qui héberge des données de santé à caractère personnel recueillies à l’occasion d’activités de prévention, de diagnostic, de soins ou de suivi médico-social, pour le compte de personne physiques ou morales à l’origine de la production ou du recueil de ces données ou pour le compte du patient lui-même ».</p>
                <p>Le champ d’application de la certification HDS comprend les activités suivantes :</p>
                <ul>
                    <li>La mise à disposition et le maintien en condition opérationnelle de la plateforme d’hébergement d’applications du système d’information</li>
                    <li>La mise à disposition et le maintien en condition opérationnelle de l’infrastructure virtuelle du système d’information utilisé pour le traitement des données de santé</li>
                    <li>L’administration et l’exploitation du système d’information contenant les données de santé</li>
                    <li>La sauvegarde de données de santé</li>
                </ul>
                <p>Il s’agit d’un certificat « hébergeur infogéreur » pour les activités de mise à disposition d’infrastructure virtuelle, de mise à disposition de plateforme logicielle, d’administration/exploitation et de sauvegarde externalisée. </p>
                <p><oncogite/> vous informe qu’il ne dispose pas pour le moment d’un hébergeur certifié HDS. Cette charte sera mise à jour quand cette certification sera assurée.</p>
                <p>Vos données ne feront l’objet d’aucun transfert en dehors de l’Union européenne dans le cadre de l’utilisation des services que nous vous proposons.</p>

                <h2 class="my-5 pt-5">10 - Cookies</h2>
                <p>Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre navigateur. Ils sont créés lorsque le navigateur d’un utilisateur charge un site internet donné : le site envoie des informations au navigateur, qui crée alors un fichier texte. Chaque fois que l’utilisateur revient sur le même site, le navigateur récupère ce fichier et l’envoie au serveur du site internet.</p>
                <p>On peut distinguer trois types de cookies, qui n’ont pas les mêmes finalités : les cookies techniques, les cookies des réseaux sociaux et les cookies publicitaires :</p>
                <ul>
                    <li>Les cookies techniques sont utilisés tout au long de votre navigation, afin de la faciliter et d’exécuter certaines fonctions. Un cookie technique peut par exemple être utilisé pour mémoriser un identifiant, un mot de passe, les réponses renseignées dans un formulaire ou encore les préférences de l’utilisateur s’agissant de la langue ou de la présentation d’un site internet, lorsque de telles options sont disponibles.</li>
                    <li>Les cookies des réseaux sociaux peuvent être créés par les plateformes sociales pour permettre aux concepteurs de sites internet de partager le contenu de leur site sur lesdites plateformes. Ces cookies peuvent notamment être utilisés par les plateformes sociales pour tracer la navigation des internautes sur le site internet concerné, qu’ils utilisent ou non ces cookies.</li>
                    <li>Les cookies publicitaires peuvent être créés non seulement par le site internet sur lequel l’utilisateur navigue, mais également par d’autres sites internet diffusant des publicités, annonces, widgets ou autres éléments sur la page affichée. Ces cookies peuvent notamment être utilisés pour effectuer de la publicité ciblée, c’est-à-dire de la publicité déterminée en fonction de la navigation de l’utilisateur.</li>
                </ul>
                <p>Nous utilisons des cookies techniques. Ceux-ci sont stockés dans votre navigateur pour une période de treize (13) mois.</p>
                <p>Nous utilisons des cookies des réseaux sociaux. Ces cookies ne sont déposés que si vous donnez votre accord. Vous pouvez vous informer sur leur nature, les accepter ou les refuser.</p>
                <p>Nous vous invitons par ailleurs à consulter les politiques de protection de la vie privée des plateformes sociales à l’origine de ces cookies, pour prendre connaissance des finalités d’utilisation des informations de navigation qu’elles peuvent recueillir grâce à ces cookies et des modalités d’exercice de vos droits auprès de ces plateformes.</p>
                <p>Nous utilisons également des cookies publicitaires. Ces cookies ne sont déposés que si vous donnez votre accord. Vous avez la possibilité de désactiver ces cookies dans les paramètres de votre navigateur web.</p>
                <p>Nous utilisons Google Analytics, qui est un outil statistique d’analyse d’audience qui génère des cookies permettant de mesurer le nombre de visites sur la Plateforme, le nombre de pages vues et l’activité des visiteurs. Votre adresse IP est également collectée pour déterminer la ville depuis laquelle vous vous connectez. La durée de conservation de ce cookie est mentionnée à l’article 7 de la présente charte.</p>
                <p>Nous vous rappelons à toutes fins utiles qu’il vous est possible de vous opposer au dépôt de cookies techniques et des cookies générés par Google Analytics, en configurant votre navigateur. Un tel refus pourrait toutefois empêcher le bon fonctionnement de la Plateforme.</p>

                <h2 class="my-5 pt-5">11 - Consentement</h2>
                <p>Lorsque vous choisissez de communiquer vos données à caractère personnel aux fins de l’exécution des services que nous proposons par l’intermédiaire de notre Plateforme, vous donnez expressément votre consentement pour la collecte et le traitement de celles-ci conformément à ce qui est énoncé à la présente charte et à la législation en vigueur.</p>

                <h2 class="my-5 pt-5">12 - Accès à vos données à caractère personnel</h2>
                <p>Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, ainsi qu’au Règlement n°2016/ 679 du 27 avril 2016 (RGPD), vous disposez du droit d’accéder à vos données (article 15 du RGPD) pour en obtenir la communication et le cas échéant en obtenir la rectification ou l’effacement (articles 16 et 17 du RGPD), à travers un accès en ligne à votre dossier. Vous pouvez également vous adresser à :</p>
                <ul>
                    <li>adresse de courrier électronique : contact@oncogite.com</li>
                    <li>adresse de courrier postal : 90 rue ST Genes 33 000 BORDEAUX</li>
                </ul>
                <p>Il est rappelé que toute personne peut, pour des motifs légitimes, demander la limitation au traitement des données la concernant (article 18 du RGPD) ou s'opposer audit traitement (articles 21 et 22 du RGPD).</p>
                <p>Nous vous informerons qu’en cas de rectification ou effacement de vos données à caractère personnel, ainsi que de limitation des traitements réalisés suite à une demande de votre part, nous notifierons lesdites modifications aux personnes auxquelles nous avons communiqués vos données, à moins qu’une telle communication s’avère impossible (article 19 du RGPD).</p>

                <h2 class="my-5 pt-5">13 - Portabilité de vos données à caractère personnel</h2>
                <p>Vous disposez d’un droit à la portabilité des données à caractère personnel que vous nous aurez fournies, entendues comme les données que vous avez déclarées activement et consciemment dans le cadre de l’accès et de l’utilisation des services, ainsi que des données générées par votre activité dans le cadre de l’utilisation des services (article 20 du RGPD). Nous vous rappelons que ce droit ne porte pas sur les données collectées et traitées sur une autre base légale que le consentement ou l’exécution du contrat nous liant.</p>
                <p>Ce droit peut être exercé gratuitement, à tout moment, et notamment lors de la fermeture de votre compte sur la Plateforme, afin de récupérer et de conserver vos données à caractère personnel.</p>
                <p>Dans ce cadre, nous vous adresserons vos données à caractère personnel, par tous moyens jugés utiles, dans un format ouvert standard couramment utilisé et lisible par machine, conformément à l’état de l’art.</p>

                <h2 class="my-5 pt-5">14 - Introduction d’une réclamation devant une autorité de contrôle</h2>
                <p>Vous êtes également informés que vous disposez du droit d’introduire une réclamation auprès d’une autorité de contrôle compétente (la Commission Nationale Informatique et Libertés pour la France) dans l’Etat membre dans lequel se trouve votre résidence habituelle, votre lieu de travail ou le lieu où la violation de vos droits aurait été commise, si vous considérez que le traitement de vos données à caractère personnel objet de la présente Charte constitue une violation des textes applicables.</p>
                <p>Ce recours pourra être exercé sans préjudice de tout autre recours devant une juridiction administrative ou juridictionnelle. En effet, vous disposez également d’un droit à un recours administratif ou juridictionnel effectif si vous considérez que le traitement de vos données à caractère personnel objet de la présente Charte constitue une violation des textes applicables.</p>

                <h2 class="my-5 pt-5">15 - Communication relative à une violation de données à caractère personnel</h2>
                <p>Si nous constatons une faille de sécurité dans le traitement de vos données susceptibles d’entraîner un risque élevé pour vos droits et libertés, nous vous en informerons dans les meilleurs délais (article 34 du RGPD). Nous vous détaillerons à cette occasion la nature de la violation rencontrée et les mesures mise en place pour y mettre un terme.</p>

                <h2 class="my-5 pt-5">16 - Modifications</h2>
                <p>Nous nous réservons le droit, à notre seule discrétion, de modifier à tout moment la présente charte, en totalité ou en partie. Ces modifications entreront en vigueur à compter de la publication de la nouvelle charte. Votre utilisation de la Plateforme suite à l’entrée en vigueur de ces modifications vaudra reconnaissance et acceptation de la nouvelle charte. A défaut et si cette nouvelle charte ne vous convient pas, vous ne devrez plus accéder à la Plateforme.</p>

                <h2 class="my-5 pt-5">17 - Entrée en vigueur</h2>
                <p>La présente charte est entrée en vigueur le 1er octobre 2020</p>

            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default
{
    props: {
        closable: {
            type: Boolean,
            default: false,
        },
    },
}
</script>