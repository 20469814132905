<template>
    <v-snackbar :timeout="6000" v-bind:color="color" top v-model="show" multi-line>
        <p class="d-flex justify-space-between align-center mb-0">
            {{message}}
            <v-btn text color="white" @click.native="show = false">
                <v-icon>close</v-icon>
            </v-btn>
        </p>
    </v-snackbar>
</template>

<script>
export default {
    data () {
        return {
            show: false,
            message: '',
            color: ''
        }
    },
    created: function () {
        this.$store.watch(this.$store.getters.getSnack, snack => {
            snack = this.$store.state.snackbar.snack;
            if (snack !== '') {
                var message = '';

                if (typeof(snack.msg) == 'string') {
                    message = snack.msg
                }
                if (typeof(snack.msg) == 'object') {
                    Object.keys(snack.msg).forEach(function(m) {
                        if (typeof(snack.msg[m]) == 'object') {
                            Object.keys(snack.msg[m]).forEach(function(k) {
                                message += snack.msg[m][k];
                            })
                        } else {
                            message += snack.msg[m];
                        }
                    })
                }

                this.show = true
                this.message = message
                this.color = snack.type
                this.$store.commit('setSnack', '')
            }
        })
    }
}
</script>
