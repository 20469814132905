import Vue from 'vue'

const state = {
  // we init state with local storage profile
  profile: localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile'))
    : {}
}

const getters = {
  getProfile: state => state.profile,
  getRegistrationProcess: state => {
    // parse registration process stored as a string
    return typeof state.profile?.registration_process === 'string'
      ? JSON.parse(state.profile.registration_process)
      : { reason: '', partner: {} }
  }
}

const actions = {
  // request profile from API and update local storage
  profileRequest: async ({ commit, dispatch }) => {
    // console.log('load profile from api')
    await Vue.axios.get('/api/user')
      .then((resp) => {
        commit('profileSuccess', resp.data.data)
      })
      .catch(() => {
        // if resp is unauthorized, logout, to
        dispatch('authLogout')
      })
  },
  updateRegistrationProcess: async ({ state, commit }, registrationProcess) => {
    console.log('update local registration', JSON.stringify(state.profile))
    commit('profileSuccess', { ...state.profile, registration_process: JSON.stringify(registrationProcess) })
  }
}

const mutations = {
  profileSuccess: (state, profile) => {
    // console.log('update local storage profile:', profile)
    localStorage.setItem('profile', JSON.stringify(profile))
    Vue.set(state, 'profile', profile)
  },
  profileRemove: (state) => {
    // console.log('clear local storage profile')
    localStorage.removeItem('profile')
    Vue.set(state, 'profile', {})
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
