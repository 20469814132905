<template>
    <v-card elevation="4" :color="isThemeAja ? '#1E3945' : 'primary'">
        <v-card-text class="text-center">
            <p class="title white--text">Cette fonctionnalité n'est disponible qu'aux abonnées OncoGite</p>

            <v-btn :to="{name: 'register'}" :color="isThemeAja ? '#4E983C' : 'secondary'" depressed>
                Adhérer
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['isThemeAja']),
    },
}
</script>
