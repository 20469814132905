import Vue from 'vue'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    meetingsRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/meetings?'+Object.entries(queries).map((e) => {
            if (typeof e[1] == 'object') {
                let s = ''
                e[1].forEach((q, i) => s += e[0]+'['+i+']='+q+(i<e[1].length-1 ? '&' : ''))
                return s
            }
            return e.join('=')
        }).join('&')

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    meetingEditRequest: async ({commit}, queries) => {
        let url = `/api/meetings/${queries.id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
    meetingCreateRequest: async ({commit}, queries) => {
        let url = `/api/meetings`;

        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
    meetingSubscribeRequest: async ({commit}, queries) => {
        let url = `/api/meetings/${queries.meeting_id}/subscribe/${queries.user_id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url)
                .then(result => {
                    resolve({'data': result.data.data, 'status': result.status})
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
    meetingUnsubscribeRequest: async ({commit}, queries) => {
        let url = `/api/meetings/${queries.meeting_id}/unsubscribe/${queries.user_id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url)
                .then(result => {
                    resolve({'data': result.data.data, 'status': result.status})
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
    meetingAttendanceRequest: async ({commit}, queries) => {
        let url = `/api/meetings/${queries.meeting_id}/attendance/${queries.user_id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': 'Action non autorisée'})
                    reject(err)
                })
        })
    },
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}