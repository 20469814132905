<template>
    <div class="user">
        <Navigation/>
        <v-main>
            <v-container v-if="$acl.not.check('isProPremium')">
                <v-row>
                    <v-col cols="12">
                        <NotPremiumCard/>
                    </v-col>
                </v-row>
            </v-container>

            <v-container v-else>
                <v-row>
                    <v-col cols="12" v-if="loading">
                        <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" v-else>
                        <UserDetails ref="UserDetails" v-model="user" v-if="user.id" color="secondary"/>
                        <v-btn @click="$router.push({ name: 'ProMeetings', params: {user_id: user.id}})" v-if="user.id" color="primary" class="align-self-right">Voir ses séances</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import UserDetails from '@/components/users/Details'
export default {
    components: {
        UserDetails,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            user: {},
            loading: false,
        }
    },
    mounted () {
        this.getUser()
    },
    methods: {
        getUser() {
            this.loading = true
            this.$store.dispatch('userRequest', {id: this.id, with: ['subscriptions.workshop', 'payments']})
                .then(result => {
                    this.user = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
    }
}
</script>